import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const Class9 = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>West Bengal Class 9 | WBBSE শ্রেণী ৯ Study Material, Notes & Solutions - QList</title>
        <meta name="description" content="WBBSE শ্রেণী ৯ (Class 9) এর বাংলা, গণিত, বিজ্ঞান, ইতিহাস সহ সব বিষয়ের জন্য নোটস, প্রশ্নোত্তর ও সল্যুশন – সব কিছু এক জায়গায়, শুধুমাত্র QList-এ।" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="West Bengal Class 9 | WBBSE শ্রেণী ৯ Study Material, Notes & Solutions - QList" />
        <meta property="og:description" content="Class 9 WBBSE ছাত্রছাত্রীদের জন্য সমস্ত বিষয়ভিত্তিক chapter-wise notes, suggestions, prosno uttor ও easy explanation – বাংলায় ও ইংরেজিতে।" />
        <meta property="og:url" content="https://www.qlist.co.in/West-Bengal-Class-9-Guide" />
        <meta name="twitter:title" content="West Bengal Class 9 | শ্রেণী ৯ WBBSE Notes & Solutions | QList" />
        <meta name="twitter:description" content="Class 9 Bengali, Math, Science, Itihas, Bhugol সহ সব WBBSE বিষয় পেতে ভিজিট করুন QList – সহজ ভাষায় সাজানো সম্পূর্ণ ফ্রি কনটেন্ট।" />
        <meta name="keywords" content="West Bengal Class 9,WBBSE শ্রেণী ৯,Class 9 Bengali Notes,WBBSE Math,Science Solutions,Class 9 History,ভূগোল,Prosno Uttor,WBBSE 2025" />
        <meta name="author" content="QList" />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://www.qlist.co.in/West-Bengal-Class-9-Guide" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                West Bengal Class 9 Guide
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      HOME
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      WEST BENGAL
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    ক্লাস ৯
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              // style="min-height: 400px;"
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src={require("../../img/about.jpg")}
                  alt=""
                  // style="object-fit: cover;"
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                ক্লাস ৯
              </h6>
              <h1 className="mb-4">Welcome to ক্লাস ৯</h1>
              <p className="mb-4">
                ক্লাস ৯ পরীক্ষা প্রস্তুতি: এখানে আসুন সহজে পড়ে সম্পূর্ণ পরীক্ষার
                সমাধান পেতে।
              </p>
              <p className="mb-4">
                ক্লাস ৯ পরীক্ষার জন্য প্রস্তুতির জন্য আমরা তৈরি করছি বিষয়ে
                বিষয়গুলির বর্ণনা। এই পরীক্ষার জন্য প্রস্তুতি নিতে বিভিন্ন
                বিষয়ে ভালো ধারণা এবং সঠিক প্রস্তুতি গুরুত্বপূর্ণ। প্রত্যেক
                বিষয়ের কোর নির্দিষ্ট এবং উন্নতির জন্য নিয়ে যাওয়া প্রশ্নপত্র,
                বই এবং অন্যান্য সূত্রের সাথে পরিচিতি উন্নতির পথে সাহায্য করতে
                পারে। আমরা ছাত্রদের মাধ্যমিক পরীক্ষার জন্য প্রস্তুতির জন্য এই
                বিষয়গুলির বর্ণনা সরবরাহ করছি যাতে তারা সঠিকভাবে প্রস্তুত হতে
                পারেন।
              </p>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    নোটস
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    প্রশ্ন এবং উত্তর
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    ব্যাখ্যা
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    এম সি কিউ
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    পরীক্ষা
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    প্রস্তুতি যাচাই
                  </p>
                </div>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() => navigate("/Bengali-Class-9-Guide")}
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    বাংলা <br /> ক্লাস ৯
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() => navigate("/English-Class-9-Guide")}
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    English <br /> ক্লাস ৯
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() => navigate("/History-Class-9-Guide")}
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    ইতিহাস <br /> ক্লাস ৯
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() => navigate("/Geography-Class-9-Guide")}
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    ভূগোল <br /> ক্লাস ৯
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() => navigate("/Life-Science-Class-9-Guide")}
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    জীবনবিজ্ঞান <br /> ক্লাস ৯
                  </h5>
                </div>
              </div>
            </div>
            {/* <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() => navigate("/Physical-Science-Class-9-Guide")}
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    ভৌতবিজ্ঞান <br /> ক্লাস ৯
                  </h5>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

Class9.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(Class9);
