import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const ChapterSample = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>CHAPTERTITLE</title>
        <meta name="description" content="CHAPTERDESCRIPTION" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                CHAPTERBREADCRUMBTITLE
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100"></div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                H6INFO
              </h6>
              <h1 className="mb-4">Welcome to className</h1>
              <p className="mb-4">
                প্রতিটি অধ্যায়ের সাথে প্রস্তুতির সহায়ক সামগ্রী ও লিঙ্ক সরবরাহ
                করা হয়েছে যাতে ছাত্ররা তাদের অধ্যয়নে সহায়তা পেতে পারেন। এই
                পৃষ্ঠাটি ছাত্রদের পড়াশোনা প্রক্রিয়ার জন্য একটি গুরুত্বপূর্ণ
                সহায়ক সরঞ্জাম হিসাবে কাজ করতে পারে।
              </p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="#"
                      onClick={() =>
                        navigate(
                          "/West-Bengal-Class-10-Bengali-Guide/GhanChakku"
                        )
                      }
                    >
                      জ্ঞানচক্ষু
                    </a>
                  </p>
                </div>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChapterSample.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ChapterSample);
