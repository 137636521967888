import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";

export function StoryWriting() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>Story Writing</title>
        <meta name="description" content="Story Writing" />
        <meta name="keywords" content="Story Writing" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Story Writing
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      // href="https://play.google.com/store/apps/details?id=com.isbacbse"
                      // className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <div>
                <font color="#ef0b0b" size="4">
                  <b>S</b>
                </font>
                <font color="#37a0e5" size="4">
                  <b>T</b>
                </font>
                <font color="#f626d7" size="4">
                  <b>O</b>
                </font>
                <font color="#2b9a09" size="4">
                  <b>R</b>
                </font>
                <font color="#02a98a" size="4">
                  <b>Y</b>
                </font>{" "}
                <font color="#2b9a09" size="4">
                  <b>W</b>
                </font>
                <font color="#ff9900" size="4">
                  <b>R</b>
                </font>
                <font color="#87099a" size="4">
                  <b>I</b>
                </font>
                <font color="#ef0b0b" size="4">
                  <b>T</b>
                </font>
                <font color="#ef0b0b" size="4">
                  <b>I</b>
                </font>
                <font color="#37a0e5" size="4">
                  <b>N</b>
                </font>
                <font color="#f626d7" size="4">
                  <b>G</b>
                </font>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#3c92e5">
                1. A farmer and a Goose. <br />
                2. A cowboy and a wolf. <br />
                3. A foolish crow and a wily fox. <br />
                4. Two Friends And A Bear <br />
                5. Lord Buddha and the mother. <br />
                6. The Woodcutter and the river-God. <br />
                7. A clever crow and fox
                <br />
                8. The man and the quarrelling sons. <br />
                9. The crow and the pitcher. <br />
                10. The Hare and the Tortoise. <br />
                11. Old Lady And Her Physician
                <br />
                12. Sailor And Monkeys
                <br />
                13. The Dog and his Shadow
                <br />
                14. The Fox and The Grapes
                <br />
                15. The Goat and The Fox
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>A farmer and a Goose</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Once there lived a poor farmer with his family. His family lived
                in poverty. He was very sad. One day, he got a magic goose as a
                gift from a well wisher. The goose laid one golden egg every
                day. The farmer sold the golden egg and bought things for his
                family. Soon they became very rich.
                <br />
                Suddenly, a very wicked idea came to the farmer’s mind. “If the
                magic goose lays one golden egg every day, how many golden eggs
                will be inside the goose? If I cut open the goose, I will become
                still rich in a single day.”
                <br />
                He killed the goose and cut her belly to open. But, he found no
                eggs inside. Because of his greed, he lost his goose and the
                golden egg.
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ MORAL : </b>{" "}
                </font>
                Greed is the root cause of miseries. <br />
                <br />
                একসময় সেখানে এক দরিদ্র কৃষক তার পরিবার নিয়ে থাকতেন। তার পরিবার
                দারিদ্র্যের মধ্যে বসবাস করত। সে খুবই দুঃখিত ছিল। একদিন, তিনি এক
                শুভাকাঙ্ক্ষীর কাছ থেকে উপহার হিসাবে একটি জাদু হংস পেয়েছিলেন।
                হংস প্রতিদিন একটি করে সোনার ডিম পাড়ে। কৃষক সোনার ডিম বিক্রি করে
                তার পরিবারের জন্য জিনিসপত্র কিনলেন। শীঘ্রই তারা খুব ধনী হয়ে
                ওঠে।
                <br />
                হঠাত্‍ করেই কৃষকের মনে একটা খুব খারাপ বুদ্ধি এল। “যাদু হংস যদি
                প্রতিদিন একটি করে সোনার ডিম দেয়, তাহলে হংসের ভিতরে কতটি সোনার
                ডিম থাকবে? আমি যদি হংসটি খুলি তবে আমি একদিনেই ধনী হয়ে যাব।"
                তিনি হংসটিকে মেরে ফেললেন এবং খোলার জন্য তার পেট কেটে ফেললেন।
                কিন্তু ভেতরে কোনো ডিম পাননি তিনি। তার লোভের কারণে, সে তার হংস
                এবং সোনার ডিম হারিয়েছিল।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ নৈতিকতা : </b>{" "}
                </font>
                লোভ দুঃখের মূল কারণ।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>A Foolish Crow and A Wolf</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Once upon a time, there was a cowboy. He used to tend his cows
                near a forest. One day while he was tending his cows, a
                mischievous idea came to his mind. Suddenly he began to cry,
                ‘Wolf’, ‘Wolf’, help me, save me. Some farmers were working in a
                field nearby. They rushed to help the cowboy. They found no wolf
                there and the boy stood giggling. The farmers became (got) angry
                and went away. Thereafter now and then, he would cry in that way
                and made fun of them. Every time they were befooled.
                <br />
                <br />
                A few days later a wolf really came. The cowboy shouted (cried
                out) for help. The farmers heard the cry but they thought that
                the cowboy was making fun as before (like other days). The wolf
                fell upon him and his cows and killed them. Then it went away.
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ MORAL : </b>{" "}
                </font>
                Nobody trusts a liar.
                <br />
                <br />
                এক সময় এক কাউবয় ছিল। তিনি একটি বনের কাছে তার গরু চরাতেন। একদিন
                তিনি যখন তার গরু চড়াচ্ছিলেন, তখন তার মাথায় একটি দুষ্টু ধারণা
                এলো। হঠাৎ সে কাঁদতে লাগল, ‘নেকড়ে’, ‘নেকড়ে’, আমাকে সাহায্য কর,
                আমাকে বাঁচাও। পাশের একটি ক্ষেতে কয়েকজন কৃষক কাজ করছিলেন। তারা
                কাউবয়কে সাহায্য করতে ছুটে গেল। তারা সেখানে কোন নেকড়ে খুঁজে পেল
                না এবং ছেলেটি হাসতে হাসতে দাঁড়িয়ে রইল। কৃষকরা রাগান্বিত হয়ে
                চলে গেল। অতঃপর সে এভাবে কান্নাকাটি করত এবং তাদের নিয়ে মজা করত।
                প্রতিবারই তাদের বোকা বানানো হয়েছে।
                <br />
                <br />
                কয়েকদিন পর সত্যিই একটা নেকড়ে এল। কাউবয় চিৎকার করে (চিৎকার
                করে) সাহায্যের জন্য। কৃষকেরা কান্না শুনতে পেলেও তারা মনে করলো,
                গোয়ালটি আগের মতোই মজা করছে (অন্য দিনের মতো)। নেকড়েটি তার এবং
                তার গরুর উপর পড়ে তাদের মেরে ফেলল। তারপর চলে গেল।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ নৈতিকতা : </b>{" "}
                </font>
                মিথ্যাবাদীকে কেউ বিশ্বাস করে না।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>TWO FRIENDS AND THE BEAR</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                One day two friends were going through a forest. They were
                talking about their love for each other. Suddenly, they saw a
                bear coming out of the jungle. One of my friends climbed up a
                tree. He did not think of the other. The other friend did not
                know how to climb a tree. He fell flat upon the ground like a
                dead man. For, he had heard that a bear does not touch a dead
                man. The bear came, and smelled his ears and nose. It took him
                for a dead man and went away.
                <br />
                <br />
                The first friend got down from the tree. He asked his friend
                what the bear had said into his ears. The latter replied, "The
                bear told me not to trust a fellow who leaves his friend in
                danger."
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ MORAL : </b>{" "}
                </font>
                A FRIEND IN NEED IS A FRIEND INDEED.
                <br />
                <br />
                একদিন দুই বন্ধু বনের মধ্য দিয়ে যাচ্ছিল। তারা একে অপরের প্রতি
                তাদের ভালবাসার কথা বলছিলেন। হঠাৎ জঙ্গল থেকে একটি ভালুককে বেরিয়ে
                আসতে দেখেন তারা। আমার এক বন্ধু গাছে উঠেছিল। অন্যের কথা সে
                ভাবেনি। অন্য বন্ধু গাছে উঠতে জানত না। সে মৃত মানুষের মত মাটিতে
                লুটিয়ে পড়ল। কারণ, তিনি শুনেছিলেন যে ভাল্লুক মৃত মানুষকে স্পর্শ
                করে না। ভালুক এসে তার কান ও নাকে গন্ধ নিল। এটি তাকে একটি মৃত
                ব্যক্তির জন্য নিয়ে গেল এবং চলে গেল।
                <br />
                <br />
                প্রথম বন্ধু গাছ থেকে নামল। সে তার বন্ধুকে জিজ্ঞেস করল ভালুক তার
                কানে কি বলেছে। উত্তরটি উত্তর দিল, "ভাল্লুক আমাকে বলেছিল যে তার
                বন্ধুকে বিপদে ফেলে এমন একজনকে বিশ্বাস করবে না।"
                <br />
                <br />
                নৈতিক: অভাবের বন্ধু প্রকৃতপক্ষে একজন বন্ধু।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>LORD BUDDHA AND THE BEREAVED MOTHER</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Once upon a time there lived a widow with her only son. She was
                very poor. One day, her son died. She was deeply shocked.
                Maddened by grief, she ran with her dead son to Lord Buddha and
                prayed to him earnestly to give back life to her only son. Lord
                Buddha with a gentle smile on his face asked the woman to bring
                some mustard seed from such a house where no one had ever died.
                The woman went from door to door searching for such a handful of
                mustard seeds, but in vain. After a long search, she came back
                to Buddha. Buddha did not give her any answer. She found the
                answer on her own. She realized that death is inevitable.
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ MORAL : </b>{" "}
                </font>
                Reality is greater than emotion.
                <br />
                <br />
                এক সময় সেখানে এক বিধবা তার একমাত্র ছেলেকে নিয়ে বসবাস করতেন।
                মেয়েটি খুব দরিদ্র ছিল। একদিন তার ছেলে মারা গেল। সে গভীরভাবে
                মর্মাহত হল। শোকে মগ্ন, তিনি তার মৃত পুত্রকে নিয়ে ভগবান বুদ্ধের
                কাছে ছুটে যান এবং তার একমাত্র পুত্রকে জীবন ফিরিয়ে দেওয়ার জন্য
                আন্তরিকভাবে প্রার্থনা করেন। ভগবান বুদ্ধ মুখে মৃদু হাসি নিয়ে
                মহিলাকে এমন একটি বাড়ি থেকে সরিষার দানা আনতে বললেন যেখানে কেউ
                মারা যায়নি। মহিলাটি ঘরে ঘরে গিয়ে এমন এক মুঠো সরিষার দানা
                খুঁজতে লাগল, কিন্তু বৃথা। অনেক খোঁজাখুঁজির পর তিনি বুদ্ধের কাছে
                ফিরে আসেন। বুদ্ধ তার কোন উত্তর দিলেন না। সে নিজেই উত্তর খুঁজে
                পেয়েছে। তিনি বুঝতে পেরেছিলেন যে মৃত্যু অনিবার্য।
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ নৈতিকতা : </b>{" "}
                </font>
                বাস্তবতা আবেগের চেয়ে বড়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>THE WOOD-CUTTER AND THE RIVER-GOD</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                One day a wood-cutter was cutting wood by the side of a river.
                Suddenly his axe slipped from his hands. It fell into the water.
                The wood-cutter began to cry as it was his only axe. The
                rivergod was moved by his cry. He appeared before him and asked,
                "Why're you crying ?" "I've lost my axe," said the wood-cutter.
                The god appeared with a golden axe and asked, "Is it yours?"
                No,' replied the wood-cutter. The god dipped into the water and
                came with a silver one. The god said, "Is it your axe?" The
                wood-cutter replied, "No, it isn't mine". At last, the rivergod
                brought his iron-axe. The wood-cutter was pleased to see his
                axe. He said, "Yes, It's mine." The river-god was extremely
                pleased with the wood-cutter at his honesty. He rewarded the
                woodcutter with all the axes.
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ MORAL : </b>{" "}
                </font>
                If you are honest, you must be awarded.
                <br />
                <br />
                একদিন এক কাঠমিস্ত্রি নদীর ধারে কাঠ কাটছিল। হঠাৎ তার হাত থেকে
                কুড়াল ছিটকে পড়ে। পানিতে পড়ে গেল। কাঠ কাটা তার একমাত্র কুড়াল
                বলে কাঁদতে লাগল। তার কান্না শুনে নদীদেবতা আন্দোলিত হল। তিনি তার
                সামনে উপস্থিত হয়ে জিজ্ঞাসা করলেন, "কাঁদছো কেন?" "আমি আমার
                কুড়াল হারিয়েছি," কাঠ কাটারটি বলল। দেবতা সোনার কুঠার নিয়ে
                হাজির হয়ে জিজ্ঞেস করলেন, এটা কি তোমার? না, কাঠ কাটার জবাব দিল।
                দেবতা জলে ডুব দিয়ে একটি রূপা নিয়ে এলেন। দেবতা বললেন, এটা কি
                তোমার কুঠার? কাঠ কাটার উত্তর দিল, "না, এটা আমার নয়"। অবশেষে নদী
                দেবতা তার লোহার কুড়াল নিয়ে এলেন। কাঠমিস্ত্রি তার কুড়াল দেখে
                খুশি হল। তিনি বললেন, হ্যাঁ, এটা আমার। নদী-দেবতা তার সততায় কাঠ
                কাটার প্রতি অত্যন্ত খুশি হলেন। তিনি সমস্ত কুড়াল দিয়ে কাঠ
                কাটারকে পুরস্কৃত করলেন।
                <br />
                <br />
                নৈতিক: আপনি যদি সৎ হন তবে আপনাকে অবশ্যই পুরস্কৃত করতে হবে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>THE FARMER AND HIS SONS</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                A farmer had many sons. They always quarrelled among themselves.
                At this the farmer remained very sad. He tried in all ways to
                restore peace in them. But he failed to do it. One day the
                farmer brought some sticks and made a bundle. He then asked each
                of them to break the bundle. They tried one by one but none
                could break it. Now their father told them to untie the bundle
                and break each stick separately. They did it very easily. The
                farmer then said to his sons, "Listen, if you live together in
                unity, nobody can harm you, on the other hand, if you always
                quarrel among yourselves and live separately outside enemies
                will break you like a single stick." Then they stopped
                quarrelling and began to live happily.
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ MORAL : </b>{" "}
                </font>
                Unity is strength.
                <br />
                <br />
                একজন কৃষকের অনেক ছেলে ছিল। সবসময় নিজেদের মধ্যে ঝগড়া হতো। এতে
                কৃষকের মন খারাপ হয়ে গেল। তিনি তাদের মধ্যে শান্তি ফিরিয়ে আনার
                জন্য সর্বাত্মক চেষ্টা করেছিলেন। কিন্তু তিনি তা করতে ব্যর্থ হন।
                একদিন কৃষক কিছু লাঠি এনে একটি বান্ডিল তৈরি করলেন। এরপর তিনি
                তাদের প্রত্যেককে বান্ডিল ভাঙতে বলেন। তারা একের পর এক চেষ্টা
                করেছে কিন্তু কেউ তা ভাঙতে পারেনি। এখন তাদের বাবা তাদের বান্ডিলটি
                খুলতে এবং প্রতিটি লাঠি আলাদাভাবে ভেঙে দিতে বলেছিলেন। তারা খুব
                সহজেই এটা করেছে। কৃষক তখন তার ছেলেদের বললেন, শোন, একতাবদ্ধভাবে
                বসবাস করলে কেউ তোমাদের ক্ষতি করতে পারবে না, অন্যদিকে সবসময়
                নিজেদের মধ্যে ঝগড়া-বিবাদে বিচ্ছিন্ন থাকলে বাইরের শত্রুরা একটি
                লাঠির মতো ভেঙে ফেলবে। তারপর তারা ঝগড়া বন্ধ করে সুখে বসবাস করতে
                লাগল।
                <br />
                <br />
                নৈতিক: ঐক্যই শক্তি।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>THE THIRSTY CROW</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Once a crow became very thirsty. He was in search of water. He
                did not find water anywhere. After a long time, he found a jar.
                He flew to it. The crow found to his dismay that there was only
                a little water at the bottom of the jar. He tried and tried to
                turn down the jar. His attempts were in vain. "How can I get the
                water?" said the crow to himself. The crow was clever. He made a
                plan. The crow saw some small stones lying near. He said to
                himself, "I'll drop the stones one by one into the jar." The
                crow picked the stones up in his beak and dropped them one by
                one into the jar. The water rose higher and higher. After
                sometime, the water came up just to the mouth of the jar. The
                water was in his reach then. He drank it to his heart's content
                and flew away from there.
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ MORAL : </b>{" "}
                </font>
                In a pinch a good use of our wits may help us out.
                <br />
                <br />
                একবার একটা কাক খুব তৃষ্ণার্ত হল। তিনি পানির সন্ধানে ছিলেন। কোথাও
                তিনি পানি পাননি। অনেকদিন পর একটা বয়াম পেল। তিনি এটিতে উড়ে
                গেলেন। কাক তার বিরক্তির সাথে দেখতে পেল যে পাত্রের নীচে সামান্য
                জল রয়েছে। তিনি চেষ্টা করেন এবং জার নামিয়ে দেওয়ার চেষ্টা করেন।
                তার প্রচেষ্টা ব্যর্থ হয়েছে। "আমি কিভাবে জল পেতে পারি?" কাক
                নিজেকে বলল।
                <br />
                <br />
                কাকটি চালাক ছিল। সে একটা পরিকল্পনা করল। কাক দেখল কাছে কিছু ছোট
                পাথর পড়ে আছে। সে মনে মনে বলল, আমি এক এক করে পাথরগুলোকে পাত্রে
                ফেলব। কাক তার ঠোঁটে পাথরগুলো তুলে একটা একটা করে পাত্রে ফেলে দিল।
                জল আরও উপরে উঠছিল। কিছুক্ষণ পর জল এসে পড়ল বয়ামের মুখে। পানি
                তখন তার হাতের নাগালে। সে তার হৃদয়ের তৃপ্তিতে তা পান করল এবং
                সেখান থেকে উড়ে গেল।
                <br />
                <br />
                নৈতিক: এক চিমটে আমাদের বুদ্ধির ভাল ব্যবহার আমাদের সাহায্য করতে
                পারে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>THE TORTOISE AND THE HARE </b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Once a hare met a tortoise. The hare laughed at the slow steps
                of the tortoise. The tortoise became angry. 'Let us run a race,
                ' said the tortoise. The race was on one fine morning. The hare
                ran fast. He was much ahead of the tortoise. The hare was sure
                of success and lay down to sleep for a while. The tortoise did
                not stop. He ran on and on. He came close to the goal. The hare
                woke up. He saw the tortoise ahead of him. He ran as fast as he
                could. But he was too late to win the race. The tortoise had
                already crossed the line. <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ MORAL : </b>{" "}
                </font>
                SLOW BUT STEADY WINS THE RACE.
                <br />
                <br />
                একবার খরগোশের দেখা মিলল কচ্ছপের সাথে। খরগোশ কচ্ছপের ধীর পদক্ষেপে
                হেসে উঠল। কচ্ছপ রেগে গেল। 'আসুন আমরা একটা দৌড় দেই,' কচ্ছপ বলল।
                দৌড় এক সুন্দর সকালে ছিল. খরগোশ দ্রুত দৌড়ে গেল। তিনি কচ্ছপের
                চেয়ে অনেক এগিয়ে ছিলেন। খরগোশ সাফল্যের বিষয়ে নিশ্চিত হয়ে
                কিছুক্ষণ শুয়ে পড়ল। কচ্ছপ থেমে থাকেনি। সে ছুটে চলল। গোলের
                কাছাকাছি আসেন তিনি। খরগোশ জেগে উঠল। সে তার সামনে কচ্ছপ দেখতে
                পেল। সে যত দ্রুত পারে দৌড়ে গেল। কিন্তু রেস জিততে তার অনেক দেরি
                হয়ে গিয়েছিল। কচ্ছপটি ইতিমধ্যেই সীমা অতিক্রম করেছে।
                <br />
                <br />
                নৈতিক: ধীর কিন্তু অবিচলিত রেস জয়।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      {/* <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a> */}
    </div>
  );
}

StoryWriting.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(StoryWriting);
