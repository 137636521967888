import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";

export function LetterWriting() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>Letter Writing</title>
        <meta name="description" content="Letter Writing" />
        <meta name="keywords" content="Letter Writing" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Letter Writing
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      // href="https://play.google.com/store/apps/details?id=com.isbacbse"
                      // className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <b>
                <font size="4" color="#fc22d7">
                  LETTER WRITING&nbsp;
                </font>
              </b>
            </div>
            <br />
            <br />
            <div>
              <font color="#ff34cf">
                1. Suppose your friend who lives in a hostel, is missing his/her
                friends and family. He is feeling very lonely and sad. Write a
                letter inspiring him/her to cope with his/her present situation.
              </font>
              <div>
                <font color="#ff34cf">
                  <br />
                </font>
              </div>
              <div>
                <font color="#ff34cf">18 Park street</font>
              </div>
              <div>
                <font color="#ff34cf">Kolkata - 700016</font>
              </div>
              <div>
                <font color="#ff34cf">20 March, 2023</font>
              </div>
              <div>
                <font color="#ff34cf">Dear Anurag,</font>
              </div>
              <div>
                <font color="#077ac7">
                  I hope this letter finds you in good health and high spirits.
                  I was saddened to hear that you are feeling lonely and
                  homesick in your hostel. I understand that living away from
                  family and friends can be challenging, but you must remember
                  that this is just a phase in your life and it too shall pass.
                </font>
              </div>
              <div>
                <font color="#077ac7">
                  <br />
                </font>
              </div>
              <div>
                <font color="#077ac7">
                  It is natural to feel homesick, but dwelling on it will only
                  make it worse. You are studying well there. Get good results.
                  You will then be able to go for higher education. Think of
                  your career. Once you are settled in a job, you will start
                  missing your hostel life. Everything is relative. Take
                  advantage of technology to stay connected with your family and
                  friends back home. They are always there to support you, no
                  matter how far away they may be.&nbsp;
                </font>
              </div>
              <div>
                <font color="#077ac7">
                  <br />
                </font>
              </div>
              <div>
                <font color="#077ac7">
                  Lastly, remember that you are not alone. There are many other
                  students in your hostel who may be feeling the same way. Try
                  to connect with them and share your experiences. You may be
                  surprised to find that many of them are feeling the same way.
                </font>
              </div>
              <div>
                <font color="#077ac7">
                  <br />
                </font>
              </div>
              <div>
                <font color="#077ac7">
                  I hope this letter has provided you with some comfort and
                  encouragement. Always remember that you are strong and capable
                  of overcoming any challenge that comes your way.
                </font>
              </div>
              <div>
                <font color="#077ac7">Take care and stay positive.</font>
              </div>
              <div>
                <font color="#ff34cf">Yours lovingly</font>
              </div>
              <div>
                <font color="#ff34cf">Raj</font>
              </div>
              <div>
                <font color="#ff34cf">Sri Anurag Roy</font>
              </div>
              <div>
                <font color="#ff34cf">19 Lake Town</font>
              </div>
              <div>
                <font color="#ff34cf">Kolkata - 700089</font>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#ff34cf">
                2. Write a letter to your friend giving him/ her a description
                of a cultural function held in your school.
              </font>
              <div>
                <font color="#077ac7">18 Park street</font>
              </div>
              <div>
                <font color="#077ac7">Kolkata - 700016</font>
              </div>
              <div>
                <font color="#077ac7">20 March, 2023</font>
              </div>
              <div>
                <font color="#077ac7">Dear Anurag,</font>
              </div>
              <div>
                <font color="#077ac7">
                  <br />
                </font>
              </div>
              <div>
                <font color="#077ac7">
                  At the beginning of the letter you take my cordial love. For a
                  long time we are away from each other .I hope you all are well
                  by the grace of God. I am also well by the grace of God. I
                  have good news to inform you. Last Saturday, the 10th June,
                  was an exciting and remarkable day for me. On the day a
                  cultural function was held in our school.
                </font>
              </div>
              <div>
                <font color="#077ac7">
                  <br />
                </font>
              </div>
              <div>
                <font color="#077ac7">
                  It was the day of the annual function of our school. It was
                  organised at our school auditorium. It was started at 10:30
                  a.m. and continued till 4:30 p.m.. The function was started
                  with an opening song. Then comes the speech from the
                  President, the Headmaster of our school. After that the topper
                  of each class and the winner of the annual sports were given
                  prizes. Some of the students recited poems. Then the students
                  of class XI &amp; class XII performed songs, dances,
                  caricatures etc.. At last a drama was staged. I have recited a
                  poem and played a role in the drama. I returned home with a
                  sweet experience.
                </font>
              </div>
              <div>
                <font color="#077ac7">
                  No more today. I shall tell you in detail when we meet.
                </font>
              </div>
              <div>
                <font color="#077ac7">My respect to your parents.</font>
              </div>
              <div>
                <font color="#077ac7">Yours lovingly</font>
              </div>
              <div>
                <font color="#077ac7">Raj</font>
              </div>
              <div>
                <font color="#077ac7">Sri Anurag Roy</font>
              </div>
              <div>
                <font color="#077ac7">19 Lake Town</font>
              </div>
              <div>
                <font color="#077ac7">Kolkata - 700089</font>
              </div>
              <div>
                <br />
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#ff34cf">
                3. write a letter to your friend about your experience in class
                10 and your long preparation for the Madhyamik examination.
              </font>
              <div>
                <font color="#077ac7">
                  <br />
                </font>
                <div>
                  <font color="#077ac7">18 Park street</font>
                </div>
                <div>
                  <font color="#077ac7">Kolkata - 700016</font>
                </div>
                <div>
                  <font color="#077ac7">20 March, 2023</font>
                </div>
                <div>
                  <font color="#077ac7">Dear Anurag,</font>
                </div>
                <div>
                  <font color="#077ac7">
                    At first you take my cordial love. I hope you are enjoying
                    the best health and prosperity. I am also fine here. I am
                    writing this letter to tell you about my preparation for the
                    Madhyamik examination.
                  </font>
                </div>
                <div>
                  <font color="#077ac7">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#077ac7">
                    This examination will start from 28th February and it will
                    end on 10th March. You will be very happy to know that my
                    preparation for this examination is very well. Since the
                    examination is coming up, I am always busy with my studies
                    almost the whole day. The syllabus of all subjects are
                    completed and now I am revising all the subjects very
                    carefully. As you know I am a little bit weak in English so
                    I have taken special care on this subject. I study this
                    subject for one hour daily at night. I have much confidence
                    about my result and I hope I will score good result in this
                    examination.
                  </font>
                </div>
                <div>
                  <font color="#077ac7">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#077ac7">
                    Overall, this is my preparation for this examination. I
                    would love to hear about your preparation for this
                    examination. Please write to me about your preparation. I
                    shall be waiting for your reply. Pay my regards to your
                    parents.
                  </font>
                </div>
                <div>
                  <font color="#077ac7">Yours lovingly</font>
                </div>
                <div>
                  <font color="#077ac7">Raj</font>
                </div>
                <div>
                  <font color="#077ac7">Sri Anurag Roy</font>
                </div>
                <div>
                  <font color="#077ac7">19 Lake Town</font>
                </div>
                <div>
                  <font color="#077ac7">Kolkata - 700089</font>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#ff34cf">
                4. suppose your friend wastes study hours by using an Android
                phone to write a letter to him giving advice as a friend.
              </font>
              <div>
                <div>
                  <font color="#077ac7">18 Park street</font>
                </div>
                <div>
                  <font color="#077ac7">Kolkata - 700016</font>
                </div>
                <div>
                  <font color="#077ac7">20 March, 2023</font>
                </div>
                <div>
                  <font color="#077ac7">Dear Anurag,</font>
                </div>
                <div>
                  <font color="#077ac7">
                    I hope you are good. I am writing this letter to advise you
                    about your phone addiction. I know you have a big addiction,
                    the thing which can put you at any risk that is mobile
                    addiction. This mobile addiction has caused you many more
                    problems to you. For the last few days I have been watching
                    you using your mobile for no reason, aunty also told me
                    about your behavior with them. It’s all because of this
                    mobile obsession, I always told you not to use mobile for no
                    reason, it is just a waste of time but you did not listen.
                    Mobile is important but addiction is dangerous.
                  </font>
                </div>
                <div>
                  <font color="#077ac7">
                    I told you last time that this mobile addiction will rein
                    your academic session and that is what is happening here.
                    For this you are disappointing us. Now for the last time I
                    am warning you not to use too much mobile, otherwise I will
                    take it back from you. Recently an office friend of mine had
                    an accident due to his negligence as he was using mobile
                    while crossing the road so I always worry about you. At the
                    end I would advise you not to use your mobile while driving
                    especially at the time of crossing the road. Convey my
                    regards to aunt and uncle. Take care.
                  </font>
                </div>
                <div>
                  <font color="#077ac7">Yours lovingly</font>
                </div>
                <div>
                  <font color="#077ac7">Raj</font>
                </div>
                <div>
                  <font color="#077ac7">Sri Anurag Roy</font>
                </div>
                <div>
                  <font color="#077ac7">19 Lake Town</font>
                </div>
                <div>
                  <font color="#077ac7">Kolkata - 700089</font>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#ff34cf">
                5. Write a letter to your friend telling him how you spent
                Independence day this year.
              </font>
              <div>
                <font color="#077ac7">
                  <br />
                </font>
                <div>
                  <font color="#077ac7">18 Park street</font>
                </div>
                <div>
                  <font color="#077ac7">Kolkata - 700016</font>
                </div>
                <div>
                  <font color="#077ac7">20 March, 2023</font>
                </div>
                <div>
                  <font color="#077ac7">Dear Anurag,</font>
                </div>
                <div>
                  <font color="#077ac7">
                    I got your nice letter just now, and I'm happy to hear from
                    you. I hope everyone in your family is doing well.
                    Thankfully, I'm also fine. You asked about how I spent
                    Independence Day, so here's a quick update.
                  </font>
                </div>
                <div>
                  <font color="#077ac7">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#077ac7">
                    Yesterday, we gathered at school around 7:30 a.m., all
                    dressed in our school uniforms. Our headmaster raised the
                    National Flag at 8:00 a.m., and we all sang the national
                    anthem together. We also sang some patriotic songs and
                    watched the N.C.C. cadets do a march past. Our headmaster
                    spoke about why Independence Day is important and reminded
                    us to honor the great people who sacrificed for our freedom.
                    Some students from Class X gave short speeches, and others
                    recited poems. Class XI students even put on a drama. After
                    all that, we got some treats like chocolates, biscuits, and
                    sweets. The whole event wrapped up around 11 a.m.
                  </font>
                </div>
                <div>
                  <font color="#077ac7">
                    I'll share more details when we meet. Give my regards to
                    your parents.
                  </font>
                </div>
                <div>
                  <font color="#077ac7">Yours lovingly</font>
                </div>
                <div>
                  <font color="#077ac7">Raj</font>
                </div>
                <div>
                  <font color="#077ac7">Sri Anurag Roy</font>
                </div>
                <div>
                  <font color="#077ac7">19 Lake Town</font>
                </div>
                <div>
                  <font color="#077ac7">Kolkata - 700089</font>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#ff34cf">
                7. write a letter to your friend who lives in Mumbai informing
                him about the greatest festival of West Bengal.
              </font>
              <div>
                <font color="#0774c7">18 Park street</font>
              </div>
              <div>
                <font color="#0774c7">Kolkata - 700016</font>
              </div>
              <div>
                <font color="#0774c7">20 March, 2023</font>
              </div>
              <div>
                <font color="#0774c7">Dear Anurag,</font>
              </div>
              <div>
                <font color="#0774c7">
                  I hope this letter finds you in the best of spirits. I am also
                  doing well on this side. I am going to tell you about the
                  festivals of West Bengal. West Bengal celebrates a lot of
                  festivals throughout the year. We celebrate festivals of every
                  religion with love and excitement. Diwali, Holi, Eid, and
                  Christmas are also celebrated with mutual respect. But the
                  important festival of West Bengal is its Durga Puja. It takes
                  place in autumn- either in September or October. This festival
                  is a celebration of the triumph of good over evil. A lot of
                  movies have beautifully showcased the celebration of Durga
                  Puja. Apart from praying in the temple and wearing new
                  clothes, there are dance performances. There is also a special
                  Puja Carnival in Kolkata during the time of Durga Puja. People
                  from all over the world gather to view this spectacle.
                </font>
              </div>
              <div>
                <font color="#0774c7">Yours lovingly</font>
              </div>
              <div>
                <font color="#0774c7">Raj</font>
              </div>
              <div>
                <font color="#0774c7">Sri Anurag Roy</font>
              </div>
              <div>
                <font color="#0774c7">19 Lake Town</font>
              </div>
              <div>
                <font color="#0774c7">Kolkata - 700089</font>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#ff34cf">
                8. Write a letter to your friend telling him how you have spent
                this puja vacation.
              </font>
              <div>
                <font color="#0774c7">
                  <br />
                </font>
                <div>
                  <font color="#0774c7">9, Ballygunge Road</font>
                </div>
                <div>
                  <font color="#0774c7">Kolkata - 700029</font>
                </div>
                <div>
                  <font color="#0774c7">17 March, 2023</font>
                </div>
                <div>
                  <font color="#0774c7">Dear [Friend’s Name],</font>
                </div>
                <div>
                  <font color="#0774c7">
                    I hope this letter finds you in good health and high
                    spirits. I am writing to share with you how I spent this
                    puja vacation. The break was much needed after a long
                    stretch of classes and exams.
                  </font>
                </div>
                <div>
                  <font color="#0774c7">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#0774c7">
                    During this puja vacation, I took a short trip to Darjeeling
                    with my family. We had a fantastic time exploring the tea
                    gardens, local markets, and enjoying the stunning views of
                    the Himalayas. We also visited the famous Darjeeling zoo and
                    the Himalayan Mountaineering Institute.
                  </font>
                </div>
                <div>
                  <font color="#0774c7">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#0774c7">
                    Apart from this, I spent some quality time with my family
                    and friends. We visited some popular restaurants and also
                    had a movie marathon at home. Overall, it was a relaxing and
                    enjoyable vacation.
                  </font>
                </div>
                <div>
                  <font color="#0774c7">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#0774c7">
                    Now that the vacation is over, I am back to my routine and
                    ready to tackle the rest of the academic year with renewed
                    energy.
                  </font>
                </div>
                <div>
                  <font color="#0774c7">
                    I hope to hear from you soon about how you spent your puja
                    vacation.
                  </font>
                </div>
                <div>
                  <font color="#0774c7">Best regards,</font>
                </div>
                <div>
                  <font color="#0774c7">Yours lovely</font>
                </div>
                <div>
                  <font color="#0774c7">Dinesh Roy</font>
                </div>
                <div>
                  <font color="#0774c7">Sri Anurag Roy</font>
                </div>
                <div>
                  <font color="#0774c7">19 Lake Town</font>
                </div>
                <div>
                  <font color="#0774c7">Kolkata - 700089</font>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#ff34cf">
                9. Write a letter to your friend words) advising her / him to
                visit the public library in her/his locality as frequently as
                possible.
              </font>
              <div>
                <font color="#0774c7">
                  <br />
                </font>
                <div>
                  <font color="#0774c7">18 Park street</font>
                </div>
                <div>
                  <font color="#0774c7">Kolkata - 700016</font>
                </div>
                <div>
                  <font color="#0774c7">20 March, 2023</font>
                </div>
                <div>
                  <font color="#0774c7">Dear Anurag,</font>
                </div>
                <div>
                  <font color="#0774c7">
                    How are you? You have not written anything for a pretty long
                    time about your health and study. What’s the matter with
                    you?
                  </font>
                </div>
                <div>
                  <font color="#0774c7">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#0774c7">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; I have come to know from
                    grandfather letter that you are not at all interested in
                    visiting the public library of your village. This is
                    unfortunate. Visiting a public library has many benefits. It
                    helps you to come out of the four walls of the house and be
                    acquainted with many people. As it is impossible to get all
                    the information from a single book, so visiting a library
                    will boost your knowledge. You can read many newspapers and
                    journals which will improve your acquire knowledge. Besides,
                    you become able to select the book your liking. The
                    diversion you get by reading is surely pleasing.
                  </font>
                </div>
                <div>
                  <font color="#0774c7">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; So I advise you to visit the
                    public library as frequently as possible. No more today. My
                    best love to you.
                  </font>
                </div>
                <div>
                  <font color="#0774c7">Yours lovingly</font>
                </div>
                <div>
                  <font color="#0774c7">Raj</font>
                </div>
                <div>
                  <font color="#0774c7">Sri Anurag Roy</font>
                </div>
                <div>
                  <font color="#0774c7">19 Lake Town</font>
                </div>
                <div>
                  <font color="#0774c7">Kolkata - 700089</font>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#ff34cf">
                10. Write a letter to your friend describing how you spent the
                summer vacation.
              </font>
              <div>
                <font color="#0774c7">
                  <br />
                </font>
                <div>
                  <font color="#0774c7">18 Park street</font>
                </div>
                <div>
                  <font color="#0774c7">Kolkata - 700016</font>
                </div>
                <div>
                  <font color="#0774c7">20 March, 2023</font>
                </div>
                <div>
                  <font color="#0774c7">Dear Anurag,</font>
                </div>
                <div>
                  <font color="#0774c7">
                    At the beginning of the letter you take my cordial love. For
                    a long time we are away from each other. I hope you all are
                    well by the grace of God. I am also fine by the grace of
                    God.You will be glad to know how I enjoyed a short tour
                    during my vacation. For the first two days of the vacation I
                    felt relaxed. I did not start my hometask.Soon we decided to
                    go to Digha with my parents. It was a lovely trip. We went
                    there by bus/ train. We noticed beauties on both sides of
                    the road. As soon as we reached Digha, the endless waves of
                    the sea seemed to welcome us. We booked a sea facing room.
                    The soothing sea seemed to embrace us. Everyday we used to
                    go for a walk on the beach and have a swim in the sea. We
                    went to a neighbouring spot, Sankarpur.We visited a temple
                    at Chandaneswar. After six days we returned home safely. I
                    will never forget this thrilling experience.
                  </font>
                </div>
                <div>
                  <font color="#0774c7">Yours lovingly</font>
                </div>
                <div>
                  <font color="#0774c7">Raj</font>
                </div>
                <div>
                  <font color="#0774c7">Sri Anurag Roy</font>
                </div>
                <div>
                  <font color="#0774c7">19 Lake Town</font>
                </div>
                <div>
                  <font color="#0774c7">Kolkata - 700089</font>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      {/* <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a> */}
    </div>
  );
}

LetterWriting.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(LetterWriting);
