/**
 *
 * WestBengalPrivacyPolicy
 *
 */

import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";

import Colors from "../../utils/Colors";

export function CbsePrivacyPolicy() {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Privacy Policy CBSE NCERT : Exam Prep QList"
        />
      </Helmet>

      <p>
        {"\n"}Privacy Policy{"\n"}
      </p>

      <div style={{ fontSize: 15, color: Colors.black }}>
        Last updated: [10-11-2024]{"\n"}
      </div>
      <br />
      <div style={{ fontSize: 15, color: Colors.black }}>
        Team Samanta built the CBSE NCERT : Exam Prep QList app as a Free app.
        This SERVICE is provided by Team Samanta at no cost and is intended for
        use as is.
        {"\n"}This page informs visitors regarding my policies regarding the
        collection, use, and disclosure of Personal Information if anyone
        decides to use my Service.{"\n"}If you choose to use my Service, then
        you agree to the collection and use of information about this policy.
        The Personal Information we collect is used to provide and improve the
        Service. I will not use or share your information with anyone except as
        described in this Privacy Policy.{"\n"}The terms used in this Privacy
        Policy have the same meanings as in our Terms and Conditions, which are
        accessible at CBSE NCERT : Exam Prep QList unless otherwise defined in
        this Privacy Policy.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Information Collection and Use
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        CBSE NCERT : Exam Prep QList does not collect any personal information
        or usage data from users. We do not require you to create an account or
        provide any personal information in order to use our app.{"\n"}We may
        use third-party services to help us improve the performance of our app
        or to provide certain features, such as analytics or advertising. These
        services may collect non-personally identifiable information such as
        device information, but we do not share any personal information with
        them.{"\n"}We do not sell or rent any information about our users to
        third parties.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Cookies
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        We do not use cookies to collect information about you or your usage of
        our App.
      </div>

      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Children's Privacy
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Our app is designed for children and we take privacy very seriously. We
        do not collect any personal information or usage data from children.
        {"\n"}We do not require children to create an account or provide any
        personal information in order to use our app.{"\n"}We do not share any
        information about our users with third parties.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Advertising
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Ads in our app/service are age-appropriate and do not contain any
        deceptive or inappropriate content. We clearly disclose any ads or
        sponsored content.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        App Content
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Our app is designed for children and we take privacy very seriously.
        This privacy policy explains what personal information we collect from
        children, how we use it, and how we protect it.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Parental Consent
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Our app is designed for children and we do not collect any personal
        information or usage data from children. Therefore, we do not require
        parental consent under the Children's Online Privacy Protection Act
        (COPPA) in the United States.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Parental Rights
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        We understand that parents have the right to protect their children's
        privacy and we take this responsibility seriously.{"\n"}Our app is
        designed to be safe for children and we do not collect any personal
        information or usage data from children.{"\n"}Parents have the right to
        review any personal information that we may have collected from their
        children. However, since we do not collect any personal information,
        there is no information to review.{"\n"}Parents also have the right to
        request that we delete any personal information that we may have
        collected from their children. However, since we do not collect any
        personal information, there is no information to delete.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Safety
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        We take safety very seriously and our app is designed to be safe for
        children. We do not collect any personal information or usage data from
        children.{"\n"}We do not require children to create an account or
        provide any personal information in order to use our app.{"\n"}We do not
        enable social features or advertising in our app. We do not allow
        inappropriate content or behavior in our app. We monitor our app to
        ensure that it remains safe and appropriate for children.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        APIs and SDKs
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Our app uses APIs and SDKs to provide enhanced functionality. We may use
        APIs to allow our app to communicate with other apps or services, such
        as social media platforms. We may also use SDKs to enable features such
        as analytics or advertising.{"\n"}We understand that the use of APIs and
        SDKs in an app designed for children requires special attention to
        privacy. Therefore, we only use APIs and SDKs from reputable providers
        that comply with privacy laws and regulations, including the Children's
        Online Privacy Protection Act (COPPA) in the United States.{"\n"}
        However, our app does not collect any personal information or usage data
        through APIs or SDKs. Any data that is collected through APIs or SDKs is
        used solely for the purpose of improving the app and providing a better
        experience for children.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Changes to This Privacy Policy
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Contact Us
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at qlistthequestionbank@gmail.com.
      </div>
    </div>
  );
}

CbsePrivacyPolicy.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(CbsePrivacyPolicy);
