import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";

export function ArticlesandPreposition() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>West Bengal Madhyamik Suggestion 2025 - Articles & Prepositions | মাধ্যমিক সাজেশন</title>
        <meta name="title" content="West Bengal Madhyamik Suggestion 2025 - Articles & Prepositions | মাধ্যমিক সাজেশন" />
        <meta name="description" content="Get the best West Bengal Madhyamik Suggestion 2025 for Articles and Prepositions. Prepare for the Madhyamik English grammar section with important questions, solved examples, and practice exercises." />
        <meta name="keywords" content="West Bengal Madhyamik Suggestion 2025, Articles and Prepositions, মাধ্যমিক সাজেশন, Madhyamik English Grammar, Madhyamik Grammar Suggestion, Madhyamik Preposition Questions, Madhyamik Articles Rules, English Second Language, WBBSE, Madhyamik Exam 2025, Madhyamik Important Questions, Madhyamik Model Paper" />
        <meta name="author" content="QList" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="West Bengal Madhyamik Suggestion 2025 - Articles & Prepositions | মাধ্যমিক সাজেশন" />
        <meta property="og:description" content="Prepare for Madhyamik 2025 English grammar with important questions on Articles and Prepositions. Get solved exercises, rules, and exam suggestions for WBBSE students." />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://www.qlist.co.in/west-bengal-madhyamik-suggestion-articles-prepositions" />
        <meta name="twitter:title" content="Madhyamik 2025 Articles & Prepositions Suggestion | মাধ্যমিক সাজেশন" />
        <meta name="twitter:description" content="Get Madhyamik 2025 suggestions for Articles and Prepositions. Learn grammar rules, important questions, and solved exercises for WBBSE English Second Language." />
        <link rel="canonical" href="https://www.qlist.co.in/west-bengal-madhyamik-suggestion-articles-prepositions" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Articles and Preposition
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      // href="https://play.google.com/store/apps/details?id=com.isbacbse"
                      // className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12">
            <p className="mb-4 mcqQuestion">
              <div>
                <b>What preposition should be used in the blank? </b>
                <br />
                <br />
                The keys are __ the bag.
                <br />
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  in
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  at
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  on
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Select the correct article from the given options : </b>
                <br />
                <br />
                He has ______ unusual talent for music.
                <br />
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>a
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  an
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  the
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  No article
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>What preposition should be used in the blank? </b>
                <br />
                <br />
                The dog is hiding __ the table.
                <br />
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  over
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  under
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  above
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Choose the correct prepositions from the given options.</b>
                <br />
                <br />
                The desks are ________ the board in the classroom.
                <br />
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  over
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  opposite
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  between
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  on
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Select the appropriate preposition:</b>
                <br />
                <br />I am not familiar __ this area.
                <br />
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  in
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  at
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  with
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>What preposition should be used in the blank? </b>
                <br />
                <br />
                The sun is shining __ the clouds.
                <br />
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  over
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  under
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  above
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>What preposition should be used in the blank? </b>
                <br />
                <br />
                The cat is sitting _______ the chair.
                <br />
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  in
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  at
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  on
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>What is the definite article?</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>A
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  An
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  The
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  No article
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Choose the correct prepositions from the given options.</b>
                <br />
                <br />
                My pencil case ________ is the book shelf right there.
                <br />
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  on
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  at
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  up
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  with
                </p>
              </div>
            </div>
            <p className="mb-4 mcqQuestion">
              <div>
                <b>Select the correct article for the word "igloo".</b>
                <br />
              </div>
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>A
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3 text-info">
                  <i className="fa fa-check text-info me-2"></i>
                  An
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  The
                </p>
              </div>
            </div>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-12">
                <p className="mb-0 px-3">
                  <i className="fa fa-arrow-right text-primary me-2"></i>
                  No article
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <div>
                <font color="#ef0b0b" size="4">
                  <b>A</b>
                </font>
                <font color="#3edde8" size="4">
                  <b>R</b>
                </font>
                <font color="#f626d7" size="4">
                  <b>T</b>
                </font>
                <font color="#2b9a09" size="4">
                  <b>I</b>
                </font>
                <font color="#ffce0d" size="4">
                  <b>C</b>
                </font>
                <font color="#2b9a09" size="4">
                  <b>L</b>
                </font>
                <font color="#ef0b0b" size="4">
                  <b>E</b>
                </font>
                <font color="#3edde8" size="4">
                  <b>S</b>
                </font>
              </div>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2b9a09" size="4">
                  <b>DEFINITION (সংজ্ঞা)</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>{" "}
                Article হলো মূলত adjective যা কোনো noun নির্দিষ্ট না অনির্দিষ্ট
                তা নির্দেশ করে। Article দুই ধরনের আছে : i. definite articles এবং
                ii. indefinite articles। <br />
                <br />
                <font color="#f626d7">
                  <b> 1. Definite Article : </b>
                </font>{" "}
                ইংরেজিতে "The" হল definite article। এটি singular or plural nou
                এর আগে ব্যবহার করা হয় যে বিশেষ্যটি একটি নির্দিষ্ট জিনিস বা
                ইতিমধ্যে উল্লেখ করা বা পরিচিত জিনিসগুলিকে বোঝায়। <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Example : </b>
                </font>{" "}
                "The cat is on the roof." (এখানে, "the" একটি নির্দিষ্ট বিড়ালকে
                নির্দেশ করে, শুধু কোনো বিড়াল নয়।) <br />
                <br />
                <font color="#f626d7">
                  <b> 2. Indefinite Articles : </b>
                </font>{" "}
                ইংরেজিতে "A" এবং "an" হল indefinite article। এগুলি singular noun
                এর আগে একটি গোষ্ঠীর কোনও সদস্যকে বোঝাতে বা নির্দিষ্টভাবে চিহ্নিত
                করা হয়নি এমন কিছু উল্লেখ করতে ব্যবহৃত হয়। <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>
                <br /> 1. "A dog is barking outside." (কোনো কুকুরকে বোঝায়, কোনো
                নির্দিষ্ট নয়।) <br />
                <br /> 2. "I saw an elephant at the zoo." (কোনো হাতি বোঝায়,
                কোনো বিশেষকে নয়।) <br />
                <br /> Articles ইংরেজি ব্যাকরণের একটি অপরিহার্য অংশ, noun
                নির্দিষ্ট কিছু বা অ-নির্দিষ্ট কিছু একটি বাক্যের মধ্যে বোঝায়
                কিনা তা নির্দিষ্ট করতে সাহায্য করে।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>Definite articles (The) ব্যাখ্যা সহ উদাহরণ </b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                <font color="#f626d7">
                  <b> 1. "The cat is on the roof." </b>
                </font>
                <br />
                ("বিড়ালটি ছাদে আছে।") <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                এই বাক্যে, একটি নির্দিষ্ট বিড়াল বোঝাতে বিশেষ্য (noun) "বিড়াল"
                বা "cat" এর আগে "the" ব্যবহৃত হয়। এটি বোঝায় যে ছাদে একটি
                নির্দিষ্ট বিড়াল রয়েছে, সম্ভবত একটি বিড়াল যা আগে উল্লেখ করা
                হয়েছিল বা বক্তা এবং শ্রোতা উভয়ের কাছেই পরিচিত। <br />
                <br />
                <font color="#f626d7">
                  <b>2. "Please pass me the book."</b>
                </font>
                <br />
                ("অনুগ্রহ করে আমাকে বইটি দিন।") <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                একটি নির্দিষ্ট বই বোঝাতে বিশেষ্য (noun) "book" এর আগে "The"
                ব্যবহার করা হয়। এটি পরামর্শ দেয় যে বক্তা একটি নির্দিষ্ট বইয়ের
                জন্য জিজ্ঞাসা করছেন যা বক্তা এবং শ্রোতা উভয়ই জানেন বা যা আগে
                আলোচনা করা হয়েছে। <br />
                <br />
                <font color="#f626d7">
                  <b>3. "I visited the Eiffel Tower last summer."</b>
                </font>
                <br />
                ("আমি গত গ্রীষ্মে আইফেল টাওয়ার পরিদর্শন করেছি।") <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                এখানে, একটি নির্দিষ্ট এবং সুপরিচিত ল্যান্ডমার্ক বোঝাতে proper
                noun "Eiffel Tower" এর আগে "the" ব্যবহার করা হয়েছে। "The"
                ব্যবহার করা হয়েছে কারণ আইফেল টাওয়ার একটি অনন্য এবং শনাক্তযোগ্য
                কাঠামো। <br />
                <br />
                <font color="#f626d7">
                  <b>4. "The students were excited about the field trip."</b>
                </font>
                <br />
                ("শিক্ষার্থীরা মাঠ ভ্রমণ নিয়ে উত্তেজিত ছিল।") <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                এই বাক্যে, "students" বিশেষ্যের (noun) আগে "the" ব্যবহার করা হয়
                ছাত্রদের একটি নির্দিষ্ট গোষ্ঠীকে বোঝানোর জন্য, সম্ভবত যারা
                পূর্বে উল্লেখিত বা কথোপকথনের প্রসঙ্গে পরিচিত ছিল। <br />
                <br />
                <font color="#f626d7">
                  <b>5. "She's the doctor who treated my father."</b>
                </font>
                <br />
                ("তিনিই সেই ডাক্তার যিনি আমার বাবার চিকিৎসা করেছিলেন।") <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                এখানে, বিশেষ্য (noun) "doctor" এর আগে "the" ব্যবহার করা হয়েছে
                একটি নির্দিষ্ট ডাক্তারকে বোঝাতে, এই ক্ষেত্রে, যিনি বক্তার পিতার
                সাথে চিকিত্সা করেছিলেন। এটি প্রসঙ্গ বা পূর্ববর্তী আলোচনা থেকে
                পরিচিত একটি নির্দিষ্ট ডাক্তারকে নির্দেশ করে। <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>{" "}
                এই উদাহরণগুলির প্রতিটিতে, "the" ব্যবহার করা হয় যে বিশেষ্যটি
                এটির পূর্বে নির্দিষ্ট কিছুকে বোঝায়, তা একটি নির্দিষ্ট বস্তু,
                স্থান, গোষ্ঠী বা ব্যক্তি, যা হয় বক্তা এবং শ্রোতা উভয়ের কাছেই
                পরিচিত। কথোপকথন থেকে বোঝা যায়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2b9a09" size="4">
                  <b>Indefinite articles (A, An) ব্যাখ্যা সহ উদাহরণ</b>
                </font>
              </div>
              <br />
              <font color="#f626d7">
                <b> 1. "I need a pen to write this note." </b>
              </font>
              <br />
              ("এই নোটটি লিখতে আমার একটি কলম দরকার।") <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              এই বাক্যে, বিশেষ্য (noun) "pen" বা "কলম" এর আগে "a" ব্যবহার করা
              হয় যে কোনও কলম বোঝাতে, নির্দিষ্ট একটি নয়। এটি নির্দেশ করে যে
              স্পিকারের লেখার জন্য উপলব্ধ যেকোনো কলম প্রয়োজন। <br />
              <br />
              <font color="#f626d7">
                <b> 2. "She wants to adopt a kitten from the shelter." </b>
              </font>
              <br />
              ("তিনি আশ্রয় থেকে একটি বিড়ালছানা দত্তক নিতে চায়।") <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              এখানে, সাধারণভাবে যেকোন বিড়ালছানাকে বোঝাতে "kitten" বা
              "বিড়ালছানা" বিশেষ্যের আগে "a" ব্যবহার করা হয়। এটি একটি নির্দিষ্ট
              বিড়ালছানা নির্দিষ্ট করে না বরং দত্তক নেওয়ার জন্য উপলব্ধ যে কোনও
              বিড়ালছানা। <br />
              <br />
              <font color="#f626d7">
                <b> 3. "He's an excellent artist." </b>
              </font>
              <br />
              ("তিনি একজন চমৎকার শিল্পী।") <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              "artist" বিশেষ্যের আগে "An" ব্যবহৃত হয়। এটি পরামর্শ দেয় যে তিনি
              কোনও নির্দিষ্ট শিল্পীকে নির্দিষ্ট না করেই শিল্পীদের বিভাগের একজন
              সদস্য। <br />
              <br />
              <font color="#f626d7">
                <b> 4. "Would you like a cup of tea?" </b>
              </font>
              <br />
              ("আপনি কি এক কাপ চা খাবেন?") <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              এই বাক্যে, "cup of tea" এর আগে "a" ব্যবহার করা হয় যে কোন কাপ চা
              অফার করতে। এটি একটি নির্দিষ্ট কাপ চায়ের উল্লেখ করে না তবে
              শ্রোতাদের ইচ্ছা হতে পারে এমন যেকোনও। <br />
              <br />
              <font color="#f626d7">
                <b> 5. "An elephant is a large mammal." </b>
              </font>
              <br />
              ("একটি হাতি একটি বড় স্তন্যপায়ী প্রাণী।") <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              এখানে, "elephant" বিশেষ্যের আগে "an" ব্যবহার করা হয়েছে যে কোনো
              হাতিকে বোঝাতে, বিশেষ কোনোটিকে নয়। এটি এক ধরণের প্রাণী হিসাবে হাতি
              সম্পর্কে একটি সাধারণ বিবৃতি। <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>{" "}
              এই উদাহরণগুলিতে, "a" এবং "an" অনির্দিষ্ট বিশেষ্য উল্লেখ করতে
              ব্যবহৃত indefinite article, ইঙ্গিত করে যে তারা যে বিশেষ্যটি সংশোধন
              করে তা একটি সাধারণ গোষ্ঠী বা বিভাগের যে কোনও সদস্য হতে পারে।
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2b9a09" size="4">
                  <b>"The" ব্যবহারের নিয়ম</b>
                </font>
              </div>
              <br />
              <font color="#f626d7">
                <b> 1. Specificity (নির্দিষ্টতা) : </b>
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#DC7633">
                <b> Rule : </b>
              </font>{" "}
              "The" একবচন বা বহুবচন বিশেষ্যের আগে ব্যবহৃত হয় বিশেষ জিনিস যা
              বক্তা এবং শ্রোতা উভয়ের কাছে পরিচিত। <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Example : </b>
              </font>{" "}
              "Please close the window." <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              "window" এর আগে "The" ব্যবহার করা হয় কারণ স্পিকার এবং শ্রোতা
              উভয়ই জানেন কোন নির্দিষ্ট জানলাটি উল্লেখ করা হচ্ছে। <br />
              <br />
              <font color="#f626d7">
                <b>
                  {" "}
                  2. Definite or Unique Entities (নির্দিষ্ট বা অনন্য সত্তা) :
                </b>
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#DC7633">
                <b> Rule : </b>
              </font>{" "}
              "The" ব্যবহার করা হয় যখন অনন্য বা নির্দিষ্ট আইটেমগুলিকে উল্লেখ
              করা হয়, যেমন - সুপরিচিত ল্যান্ডমার্ক, উচ্চতর জিনিস বা একই জাতীয়
              কোনো জিনিস বোঝাতে। <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Example : </b>
              </font>{" "}
              "He's the best player on the team." <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              সেই নির্দিষ্ট দলের মধ্যে সর্বোচ্চ দক্ষতার স্তর নির্দেশ করতে "best"
              এর আগে "The" ব্যবহার করা হয়। <br />
              <br />
              <font color="#f626d7">
                <b>
                  {" "}
                  3. Specific Groups or Categories (নির্দিষ্ট গ্রুপ বা বিভাগ) :
                </b>
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#DC7633">
                <b> Rule : </b>
              </font>{" "}
              একবচন বা বহুবচন বিশেষ্যের আগে "The" ব্যবহার করা হয় একটি নির্দিষ্ট
              গোষ্ঠী, প্রতিষ্ঠান বা সংস্থাকে বোঝাতে। <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Example : </b>
              </font>{" "}
              "The students gathered in the auditorium." <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              সেই প্রসঙ্গে ছাত্রদের একটি নির্দিষ্ট গোষ্ঠীকে বোঝাতে "students"
              আগে "The" ব্যবহার করা হয়। <br />
              <br />
              <font color="#f626d7">
                <b> 4. Definite Ordinals (নির্দিষ্ট নিয়মাবলী) :</b>
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#DC7633">
                <b> Rule : </b>
              </font>{" "}
              "The" একটি নির্দিষ্ট দৃষ্টান্ত বা ঘটনা নির্দিষ্ট করতে ক্রমিক
              সংখ্যার সাথে ব্যবহার করা হয়। <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Example : </b>
              </font>{" "}
              "She's the second person to arrive." <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              আগমনকারীদের মধ্যে একটি নির্দিষ্ট অবস্থান নির্দেশ করতে "second" এর
              আগে "The" ব্যবহার করা হয়। <br />
              <br />
              <font color="#f626d7">
                <b>
                  {" "}
                  5. Previously Mentioned or Recognizable Objects (পূর্বে
                  উল্লেখিত বা স্বীকৃত বস্তু):
                </b>
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#DC7633">
                <b> Rule : </b>
              </font>{" "}
              "The" ব্যবহার করা হয় যখন কোনো বস্তু, মানুষ বা স্থানের উল্লেখ করার
              সময় যা আগে উল্লেখ করা হয়েছে বা একটি নির্দিষ্ট প্রসঙ্গের মধ্যে
              স্বীকৃত। <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Example : </b>
              </font>{" "}
              "Please pass me the book on the table." <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              "book" এর আগে "The" ব্যবহার করা হয় কারণ এটি ইতিমধ্যে পরিচিত বা
              দৃশ্যমান একটি নির্দিষ্ট বইকে বোঝায়। <br />
              <br />
              <font color="#f626d7">
                <b> 6. মহাসাগর, সমুদ্র, নদী, পর্বত :</b>
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#DC7633">
                <b> Rule : </b>
              </font>{" "}
              নদী, সমুদ্র, মহাসাগর, পর্বতশ্রেণী এবং অন্যান্য ভৌগলিক বৈশিষ্ট্যের
              নামের আগে "The" ব্যবহার করা হয়। <br />
              <br />
              <font color="#f626d7">1. Oceans (মহাসাগর) : </font> The Pacific
              Ocean (প্রশান্ত মহাসাগর), The Atlantic Ocean(আটলান্টিক মহাসাগর),
              The Indian Ocean(ভারত মহাসাগর), The Arctic Ocean(আর্কটিক মহাসাগর){" "}
              <br />
              <br />
              <font color="#f626d7">2. Seas (সমুদ্র) : </font> The Mediterranean
              Sea (ভূমধ্যসাগর), The Caribbean Sea (ক্যারিবিয়ান সাগর), The Red
              Sea (লোহিত সাগর), The Arabian Sea (আরব সাগর) <br />
              <br />
              <font color="#f626d7">3. Rivers (নদী) : </font> The Amazon River
              (আমাজন নদী), The Nile River (নীল নদ), The Mississippi River
              (মিসিসিপি নদী), The Yangtze River (ইয়াংজি নদী) <br />
              <br />
              <font color="#f626d7">4. Mountains (পাহাড়) : </font> The
              Himalayas (হিমালয়), The Andes (আন্দিজ), The Rockies (Rocky
              Mountains) [রকিজ (রকি পর্বত)], The Alps (আল্পস) <br />
              <br />, , ,{" "}
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Example : </b>
              </font>{" "}
              "The Nile is the longest river in Africa." <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              একটি নির্দিষ্ট নদী বোঝাতে "Nile" এর আগে "The" ব্যবহার করা হয়।{" "}
              <br />
              <br />
              <font color="#f626d7">
                <b> 7. প্রতিষ্ঠান, সংস্থা এবং অনন্য বস্তু :</b>
              </font>
              <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#DC7633">
                <b> Rule : </b>
              </font>{" "}
              "The" নির্দিষ্ট প্রতিষ্ঠান, সংস্থা এবং অনন্য বস্তুর আগে ব্যবহৃত
              হয়। <br />
              <br />
              <font color="#f626d7">1. Institutions (প্রতিষ্ঠান) : </font>The
              Louvre Museum, The University of Oxford, The Smithsonian
              Institution, The White House, The Metropolitan Museum of Art{" "}
              <br />
              <br />
              <font color="#f626d7">2. Organization (সংস্থা) :</font> The United
              Nations (UN), The Red Cross, The World Health Organization (WHO),
              The European Space Agency (ESA) <br />
              <br />
              <font color="#f626d7">3. Unique Objects (অনন্য বস্তু) :</font> The
              Eiffel Tower, The Great Wall of China, The Mona Lisa, The Statue
              of Liberty, The Taj Mahal <br />
              <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Example : </b>
              </font>{" "}
              "They visited the Louvre Museum in Paris." <br />
              <font color="#f626d7">
                <b> ❑ </b>
              </font>
              <font color="#2a9a08">
                <b> Explanation : </b>
              </font>{" "}
              "The" ব্যবহার করা হয় "Louvre Museum" এর আগে কারণ এটি একটি
              নির্দিষ্ট এবং স্বীকৃত প্রতিষ্ঠানকে বোঝায়।
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2b9a09" size="4">
                  <b>"A" এবং "An" ব্যবহারের নিয়ম</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                <font color="#f626d7">
                  {" "}
                  1. একটি ব্যঞ্জনবর্ণ শব্দ বা ব্যঞ্জনবর্ণ ধ্বনি (consonant
                  sound) সহ যেকোনো স্বরবর্ণের (vowel) আগে article 'a' ব্যবহৃত
                  হয়।{" "}
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Examples : </b>
                </font>
                <br />
                <br /> i. A man is sitting on the chair. <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                'man' শব্দের আগে indefinite article 'a' ব্যবহৃত হয়। এটি এই
                নিয়ম অনুসরণ করে যে ব্যঞ্জনধ্বনি দিয়ে শুরু হওয়া শব্দের আগে 'a'
                ব্যবহার করা হয়। এই ক্ষেত্রে, 'man' ব্যঞ্জনবর্ণ ধ্বনি /m/ দিয়ে
                শুরু হয়। <br />
                <br /> ii. She goes to a university in Delhi. <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                'university' শব্দের আগে indefinite article 'a' ব্যবহৃত হয়। এটি
                নিয়ম অনুসরণ করে যে ব্যঞ্জনবর্ণ ধ্বনি (consonant sound) দিয়ে
                শুরু হওয়া শব্দ বা ব্যঞ্জনবর্ণ ধ্বনি সহ যেকোনো স্বরবর্ণ বা vowel
                শব্দের আগে 'a' ব্যবহার করা হয়। <br />
                <br />
                <font color="#f626d7">
                  {" "}
                  2. একইভাবে, একটি vowel (a, e, i, o, u) বা vowel sound যুক্ত
                  consonant word এর অক্ষর দিয়ে শুরু হওয়া শব্দের আগে ‘an’
                  articleটি ব্যবহৃত হয়।
                </font>
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Examples : </b>
                </font>
                <br />
                <br /> i. She is an innocent girl. <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                'innocent' এর আগে 'an' শব্দটি ব্যবহৃত হয়। এটি ব্যবহার করা
                হয়েছে কারণ 'innocent' vowel 'i' এর মতো একটি শব্দ দিয়ে শুরু
                হয়। কল্পনা করুন আপনি জোরে 'innocent' বলছেন এবং লক্ষ্য করুন যে
                এটি কীভাবে 'i' ধ্বনি দিয়ে শুরু হয়, যা একটি স্বরধ্বনির মতো।{" "}
                <br />
                <br /> ii. He is an honest man. <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                'honest' এর আগে 'an' শব্দটি আসে। এটি ব্যবহার করা হয়েছে কারণ
                'honest' স্বরধ্বনি (vowel) 'o' এর মতো একটি শব্দ দিয়ে শুরু হয়।
                আপনি যদি উচ্চস্বরে 'honest' বলেন, আপনি লক্ষ্য করবেন এটি 'o'
                ধ্বনি দিয়ে শুরু হয়, যা একটি স্বরধ্বনির মতো। <br />
                <br />
                <font color="#f626d7">
                  {" "}
                  3. একটি একবচন common noun এর জন্য সর্বদা একটি article 'a' বা
                  'an' প্রয়োজন, কিন্তু একটি বহুবচন common noun এর আগে কোনো
                  article এর প্রয়োজন হয় না বা সেই বিশেষ্যটিকে নির্দিষ্ট করার
                  জন্য 'the' থাকতে পারে।
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Examples : </b>
                </font>
                <br />
                <br /> i. "I saw an elephant." (একটি এলোমেলো হাতি বোঝায়) <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                এই বাক্যটিতে একবচন common noun 'elephant'-এর আগে indefinite
                article 'an' ব্যবহার করা হয়েছে। প্রথমবার একটি একবচন countable
                noun প্রবর্তন করার সময় বা একটি অ-নির্দিষ্ট উদাহরণ উল্লেখ করার
                সময়, একটি indefinite article ('a' বা 'an') ব্যবহার করা হয়। এই
                ক্ষেত্রে, 'an' একটি হাতির একটি অনির্দিষ্ট উদাহরণ উপস্থাপন করে,
                যে কোনো একটি হাতিকে উল্লেখ করে। <br />
                <br /> ii. "I saw elephants in a zoo." (No article is required){" "}
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Explanation : </b>
                </font>{" "}
                এখানে, 'elephants' একটি plural common noun। সাধারণ বিবৃতিতে বা
                অ-নির্দিষ্ট উপায়ে plural countable noun উল্লেখ করার সময়, কোনো
                article এর প্রয়োজন নেই। বহুবচন রূপ 'elephants' কোনো নির্দিষ্ট
                কিছু উল্লেখ না করে একাধিক হাতিকে বোঝায়। <br />
                <br />
                <font color="#f626d7">
                  {" "}
                  4. একটি Proper noun কে একটি common noun করতে A/An ব্যবহার করা
                  হয়। Proper noun গুলি article গ্রহণ করে না তবে তাদের common
                  noun করতে, a/an ব্যবহার করা হয়।
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Examples : </b>
                </font>
                <br />
                <br /> i. He thinks he is an Abraham Lincon. (প্রকৃত ব্যক্তিকে
                উল্লেখ না করে বরং তার মতো কাউকে উল্লেখ করা) <br />
                <br /> ii. She is an Indian. (India একটি proper noun কিন্তু
                ‘Indian’ একটি common noun) <br />
                <br />
                <font color="#f626d7">
                  {" "}
                  5. Indefinite article 'a' বা 'an' কখনও কখনও সংখ্যা উল্লেখ করতে
                  ব্যবহৃত হয়।
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Examples : </b>
                </font>
                <br />
                <br /> i. The baby is playing with a doll (one doll) <br /> ii.
                I owe him a thousand bucks. (one thousand dollars) <br /> iii.
                He drove 120 kilometres in an hour (one hour) <br />
                <br />
                <font color="#f626d7">
                  {" "}
                  6. Indefinite article (a/an) descriptive adjective এর আগে
                  ব্যবহৃত হয় :{" "}
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Examples : </b>
                </font>
                <br /> i. She is an amazing dancer. <br /> ii. What a nice
                Villa! <br />
                <br />
                <font color="#f626d7">
                  {" "}
                  7. Indefinite article টি নির্ধারকদের সাথে ব্যবহার করা হয়
                  (few, lot, most)
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2a9a08">
                  <b> Examples : </b>
                </font>
                <br /> i. There is a little drink in the glass <br /> ii. I have
                a few guests coming over
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2b9a09" size="4">
                  <b>Use of "A"</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                <font color="#f626d7">
                  {" "}
                  1. একটি consonant word বা consonant sound সহ যেকোনো স্বরবর্ণের
                  (vowel) আগে article টি 'a' ব্যবহৃত হয়।{" "}
                </font>
                <br />
                <br /> i. A car (কারণ "car" ব্যঞ্জনধ্বনি দিয়ে শুরু হয় /k/){" "}
                <br /> ii. A dog (কারণ "dog" ব্যঞ্জনধ্বনি দিয়ে শুরু হয় /d/){" "}
                <br /> iii. A book (কারণ "book" ব্যঞ্জনধ্বনি দিয়ে শুরু হয় /b/){" "}
                <br /> iv. A computer (কারণ "computer" ব্যঞ্জনধ্বনি (consonant
                sound) দিয়ে শুরু হয় /k/) <br /> v. A university (কারণ
                "university" "y" ধ্বনি (sound) দিয়ে শুরু হয়, যা একটি
                ব্যঞ্জনবর্ণ ধ্বনি (consonant sound) বলে বিবেচিত হয়।) <br />
                <br />
                <font color="#f626d7">
                  {" "}
                  2. বিশেষ্যের (noun) প্রথম অক্ষরটি যদি vowel হয় কিন্তু
                  উচ্চারিত হয় "উ" বা "u" এর মতো, তবে article টি সেই noun এর আগে
                  হবে।
                </font>
                <br />
                <br /> i. A university (কারণ "university" উচ্চারিত হয়
                "yoo-ni-ver-si-ty" শুরুতে "y" ধ্বনি দিয়ে।) <br /> ii. A unique
                opportunity (কারণ "unique" উচ্চারিত হয় "yoo-neek" এর শুরুতে "y"
                ধ্বনি আছে।) <br /> iii. A useful tool (কারণ "useful" এর শুরুতে
                "y" ধ্বনি সহ "yoo-sful" উচ্চারিত হয়) <br /> iv. A European
                country (কারণ "European" উচ্চারিত হয় "yoo-ro-pee-an" এর শুরুতে
                "y" ধ্বনি ) <br />
                <br />
                <font color="#f626d7">
                  {" "}
                  3. যদি কোনো বিশেষ্যের প্রথম অক্ষরটি vowel হয় কিন্তু উচ্চারিত
                  হয় "oa" বা "ওয়া" এর মতো, তবে article টি সেই বিশেষ্যের আগে
                  হবে।
                </font>
                <br />
                <br /> i. A one-of-a-kind experience (কারণ "one" উচ্চারণ করা হয়
                "ওয়ান" একটি "w" sound এর সাথে) <br /> ii. A once-in-a-lifetime
                opportunity (কারণ "once" একটি "w" শব্দের সাথে "wuns" বা ওয়ান্স
                উচ্চারিত হয়) <br /> iii. A one-man show (কারণ "one" উচ্চারণ করা
                হয় "wun" বা ওয়ান একটি "w" ধ্বনির বা sound এর সাথে) <br /> iv. A
                one-way street (কারণ "one" উচ্চারণ করা হয় "wun" বা ওয়ান একটি
                "w" ধ্বনির বা sound এর সাথে)
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2b9a09" size="4">
                  <b>"An" এর ব্যবহার</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                <font color="#f626d7">
                  1. একইভাবে, একটি vowel (a, e, i, o, u) বা vowel sound যুক্ত
                  consonant word এর অক্ষর দিয়ে শুরু হওয়া শব্দের আগে ‘an’
                  article টি ব্যবহৃত হয়।
                </font>
                <br />
                <br /> i. An apple (কারণ "apple" স্বরধ্বনি বা vowel sound দিয়ে
                শুরু হয় /আ/) <br /> ii. An hour (কারণ "hour" স্বরধ্বনি বা vowel
                sound দিয়ে শুরু হয় /আউ/) <br /> iii. An honest person (কারণ
                "honest" স্বরধ্বনি বা vowel sound দিয়ে শুরু হয় /অ/) <br />
                <br />
                <font color="#f626d7">
                  2. যদি বিশেষ্যের প্রথম অক্ষরটি একটি consonant "h" হয় কিন্তু
                  "h" উচ্চারিত না হয়, তাহলে article "An" বিশেষ্যের আগে হবে।
                </font>
                <br />
                <br /> i. An hour (কারণ "hour" একটি নীরব "h" উচ্চারিত না হয়ে
                "ow-er" বা ওউ-আর উচ্চারিত হয়) <br /> ii. An honest person (কারণ
                "honest" নীরব "h" উচ্চারিত না হয়ে "অন-এস্ট" উচ্চারিত হয়) <br />{" "}
                iii. An heirloom (কারণ "heirloom" একটি নীরব "h" উচ্চারিত না হয়ে
                "এয়ার-লুম" উচ্চারিত হয়) <br /> iv. An honor (কারণ "honor" একটি
                নীরব "h" এর সাথে "on-er" উচ্চারিত হয়) <br />
                <br />
                <font color="#f626d7">
                  3. কিছু সংক্ষিপ্ত রূপ আছে যেগুলি consonant দিয়ে শুরু হয় কিন্তু
                  একটি স্বরবর্ণের বা vowel এর মতো উচ্চারিত হয়, তাহলে সেই
                  বিশেষ্যের আগে "An" হবে।
                </font>
                <br />
                <br /> i. An MRI scan (কারণ "MRI" উচ্চারিত হয় "এম আর আই" ("em
                ar eye") স্বরধ্বনির বা vowel sound সাথে /এ/) <br /> ii. An NGO
                (কারণ "NGO" উচ্চারিত হয় "এন-জি-ওহ" স্বরধ্বনির বা vowel sound
                সাথে /এ/) <br /> iii. An FAQ (কারণ "FAQ" উচ্চারিত হয় "এফ-এ-কিউ
                " স্বরধ্বনির বা vowel sound সাথে /এ/) <br /> iv. An LSD trip
                (কারণ "LSD" উচ্চারিত হয় "এল-এস-ডি" স্বরধ্বনির বা vowel sound
                সাথে /এ/)
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2b9a09" size="4">
                  <b>"The" এর ব্যবহার</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                <font color="#f626d7">
                  1. "The" article টি একটি নির্দিষ্ট বিশেষ্যের বা noun এর আগে
                  থাকবে।
                </font>
                <br />
                <br /> That is a man. The man is old. <br />
                <br />
                <font color="#f626d7">
                  2. একটি বর্ণ বা শ্রেণী নির্দেশ করতে একটি বিশেষ্যের আগে "The"
                  article টি আসবে।{" "}
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>{" "}
                The dog, The tiger, The lion, The man, The bird <br />
                <br />
                <font color="#f626d7">
                  3. একটি জাতি বা সম্প্রদায় নির্দেশ করতে একটি বিশেষ্যের আগে
                  "The" article টি আসবে।
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>{" "}
                The Indians, The Hindus, The Americans, The Asians, The Muslims,
                The Australians, The Chinese, The Germans. <br />
                <br />
                <font color="#f626d7">
                  4. "The" article টি একটি বিশেষ্য নদী, সমুদ্র, উপসাগর, মহাসাগর,
                  পর্বত, দ্বীপ, জাহাজ নির্দেশ করতে ব্যবহৃত হয়।
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>{" "}
                The Nile River, The Ganges River, The Caribbean Sea, The San
                Francisco Bay, The Tokyo Bay, The Atlantic Ocean, The Indian
                Ocean, The Mount Everest, The Kilimanjaro, Hawaii (The Big
                Island), Sicily, The Titanic, <br />
                <br />
                <font color="#f626d7">
                  5. "The" article টি একটি বিশেষ্যের আগে আসবে যা ধর্মগ্রন্থের
                  একটি নাম নির্দেশ করবে।
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>{" "}
                The Bible, The Ramayana, The Mahabharata, The Vedas, The Geeta,
                The Koran, The Torah. <br />
                <br />
                <font color="#f626d7">
                  6. একটি সংবাদপত্র নির্দেশ করার জন্য একটি noun এর আগে "The"
                  article টি আসবে।
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>{" "}
                The New York Times, The Times, The Telegraph, USA Today The
                Statement, The Ananda Bazar Patrika etc. <br />
                <br />
                <font color="#f626d7">
                  7. "The" article টি একটি noun এর আগে আসবে যা বিশ্বের একমাত্র
                  জিনিস নির্দেশ করবে।{" "}
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>{" "}
                The sun, The moon, The sky, The earth. <br />
                <br />
                <font color="#f626d7">
                  8. একটি বিখ্যাত ঐতিহাসিক ঘটনা নির্দেশ করতে একটি noun এর আগে
                  "The" article টি আসবে।{" "}
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>{" "}
                The sepoy Mutiny, The American Revolution, The Industrial
                Revolution, The Partition of India, The Moon Landing. <br />
                <br />
                <font color="#f626d7">
                  9. "The" article টি একটি noun এর আগে আসবে যা একটি বিখ্যাত ভবন
                  এবং স্মৃতিস্তম্ভ নির্দেশ করবে।
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>{" "}
                The Tajmahal, The Kanarak Temples, The Eiffel Tower, The Great
                Wall of China, The Burj Khalifa, The Statue of Liberty. <br />
                <br />
                <font color="#f626d7">
                  10. "The" article টি দিক নির্দেশ করার জন্য একটি noun এর আগে
                  আসবে।
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>{" "}
                The East, The West, The South, The North etc. <br />
                <br />
                <font color="#f626d7">
                  11. "The" article টি তারিখ বোঝাতে ব্যবহৃত হয়।
                </font>
                <br />
                <br />
                <font color="#f626d7">
                  <b> ❑ </b>
                </font>
                <font color="#2b9a09">
                  <b> Examples : </b>
                </font>{" "}
                The 15 August, The 26 January, The 25 December etc.
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#ef0b0b" size="4">
                  <b>W</b>
                </font>
                <font color="#3edde8" size="4">
                  <b>I</b>
                </font>
                <font color="#f626d7" size="4">
                  <b>T</b>
                </font>
                <font color="#2b9a09" size="4">
                  <b>H</b>
                </font>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      )}
      <br />
      {/* <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a> */}
    </div>
  );
}

ArticlesandPreposition.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ArticlesandPreposition);
