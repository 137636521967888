import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";

export function ProcessingFlowChart() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>Processing/Flow Chart</title>
        <meta name="description" content="Processing/Flow Chart" />
        <meta name="keywords" content="Processing, Flow Chart" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Processing/Flow Chart
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      // href="https://play.google.com/store/apps/details?id=com.isbacbse"
                      // className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <div>
                <font color="#ef0b0b" size="4">
                  <b>P</b>
                </font>
                <font color="#37a0e5" size="4">
                  <b>R</b>
                </font>
                <font color="#f626d7" size="4">
                  <b>O</b>
                </font>
                <font color="#2b9a09" size="4">
                  <b>C</b>
                </font>
                <font color="#02a98a" size="4">
                  <b>E</b>
                </font>
                <font color="#ff9900" size="4">
                  <b>S</b>
                </font>
                <font color="#ef0b0b" size="4">
                  <b>S</b>
                </font>
                <font color="#87099a" size="4">
                  <b>I</b>
                </font>
                <font color="#f626d7" size="4">
                  <b>N</b>
                </font>
                <font color="#2b9a09" size="4">
                  <b>G</b>
                </font>{" "}
                <font color="#ff9900" size="4">
                  <b>W</b>
                </font>
                <font color="#87099a" size="4">
                  <b>R</b>
                </font>
                <font color="#ef0b0b" size="4">
                  <b>I</b>
                </font>
                <font color="#ef0b0b" size="4">
                  <b>T</b>
                </font>
                <font color="#37a0e5" size="4">
                  <b>I</b>
                </font>
                <font color="#f626d7" size="4">
                  <b>N</b>
                </font>
                <font color="#37a0e5" size="4">
                  <b>G</b>
                </font>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#3c92e5">
                1. How the Mask is prepared <br />
                2. How the Sanitizer is Prepared <br />
                3.Preparing Paper Sheet <br />
                4. How to make orange juice <br />
                5. How to prepare School Magazine <br />
                6. How to make a Building <br />
                7. Preparation of Mustard Oil <br />
                8. Preparation of O.R.S <br />
                9. Preparation of Tomato Jelly/ Mango Jelly <br />
                10. Preparation of Potato Chips.{" "}
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2a9a08" size="4">
                  <b>PREPARATION OF MASKS </b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                It is a very interesting matter to know how masks are prepared.
                The preparation of mask involves several sequential steps.At
                first, thin clothes and ropes are bought from the market.Then
                the clothes are cut into small pieces.Next,the sides are folded
                and sewed properly to form the mask structure.Simultaneously,
                the ropes are carefully cut into appropriate lengths to create
                loops for securing the mask. The next phase involves stitching
                these loops seamlessly with the cloth.Once the main assembly is
                complete, a final touch includes the trimming of any excess
                cloth or thread, ensuring a neat finish. After this
                comprehensive process, the mask is deemed ready for use.
                <br />
                <br />
                মুখোশ কীভাবে প্রস্তুত করা হয় তা জানা খুব আকর্ষণীয় বিষয়। মুখোশ
                তৈরিতে বেশ কয়েকটি ধারাবাহিক ধাপ জড়িত। প্রথমে বাজার থেকে পাতলা
                কাপড় এবং দড়ি কেনা হয়। তারপর কাপড়গুলোকে ছোট ছোট টুকরো করে
                কেটে নেওয়া হয়। এরপর, মুখোশের কাঠামো তৈরির জন্য পাশগুলোকে ভাঁজ
                করে সেলাই করা হয়। একই সঙ্গে দড়িগুলো মাস্ক সুরক্ষিত করার জন্য
                লুপ তৈরি করতে সাবধানে যথাযথ দৈর্ঘ্যে কাটা হয়। পরের ধাপে এই
                লুপগুলিকে কাপড়ের সাথে নির্বিঘ্নে সেলাই করা জড়িত৷ একবার মূল
                সমাবেশ সম্পূর্ণ হয়ে গেলে, একটি চূড়ান্ত স্পর্শে কোনও অতিরিক্ত
                কাপড় বা থ্রেড ছাঁটাই করা, একটি ঝরঝরে ফিনিস নিশ্চিত করা
                অন্তর্ভুক্ত৷ এই ব্যাপক প্রক্রিয়ার পরে, মুখোশটি ব্যবহারের জন্য
                প্রস্তুত বলে মনে করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2a9a08" size="4">
                  <b>PREPARATION OF HAND SANITIZER </b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                At present, hand sanitizer is a very important item in our daily
                life. It is usually used to kill germs on the hands.The
                processing of hand sanitizer has to undergo a chain of
                processes. First of all, aloe vera gel, rubbing or isopropyl
                alcohol, hydrogen peroxide, distilled water and essential oil
                are brought from the market. 2/3 cups rubbing or isopropyl
                alcohol and 2 tablespoon aloe vera gel are poured into a bowl.
                Then they are mixed properly with a spoon. Next,distilled water
                and hydrogen peroxide are added to the solution and stirred
                well. After that, a few drops of essential oil is added to the
                solution for a nice fragrance. Now the hand sanitizer is ready
                to use. Finally, the whole solution is poured into the spray
                bottle.
                <br />
                <br />
                বর্তমানে, হ্যান্ড স্যানিটাইজার আমাদের দৈনন্দিন জীবনে একটি
                অত্যন্ত গুরুত্বপূর্ণ জিনিস। এটি সাধারণত হাতের জীবাণু মেরে ফেলার
                জন্য ব্যবহৃত হয়। হ্যান্ড স্যানিটাইজার প্রক্রিয়াকরণ প্রক্রিয়ার
                একটি শৃঙ্খলের মধ্য দিয়ে যেতে হয়। প্রথমত, অ্যালোভেরা জেল, ঘষা
                বা আইসোপ্রোপাইল অ্যালকোহল, হাইড্রোজেন পারক্সাইড, পাতিত জল এবং
                এসেনশিয়াল অয়েল বাজার থেকে আনা হয়। 2/3 কাপ ঘষা বা আইসোপ্রোপাইল
                অ্যালকোহল এবং 2 টেবিল চামচ অ্যালোভেরা জেল একটি পাত্রে ঢেলে
                দেওয়া হয়। তারপর তারা একটি চামচ সঙ্গে সঠিকভাবে মিশ্রিত করা হয়।
                এর পরে, পাতিত জল এবং হাইড্রোজেন পারক্সাইড দ্রবণে যোগ করা হয় এবং
                ভালভাবে নাড়তে হয়। এর পরে, একটি সুন্দর সুবাসের জন্য সমাধানে
                কয়েক ফোঁটা অপরিহার্য তেল যোগ করা হয়। এখন হ্যান্ড স্যানিটাইজার
                ব্যবহারের জন্য প্রস্তুত। অবশেষে, পুরো সমাধানটি স্প্রে বোতলে ঢেলে
                দেওয়া হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2a9a08" size="4">
                  <b>PREPARATION OF PAPER</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Paper is a very useful item in our daily life. It plays a great
                role in spreading education. The development of our society
                depends largely on it. It is made through some interlinked
                stages. At first, bamboo is cut down into clumps. Then they are
                cleaned and cut into small pieces. Next, the pieces are ground
                into a big machine to make pulp. Later, chemicals are added to
                make it soft and white. Now the soft pulp is rolled into sheets.
                Thereafter, colour is added to the paper sheets. Finally, the
                sheets are dried and cut into sizes. The Paper is now ready for
                use.
                <br />
                <br />
                কাগজ আমাদের দৈনন্দিন জীবনে খুব দরকারী জিনিস। এটি শিক্ষা বিস্তারে
                বিরাট ভূমিকা পালন করে। আমাদের সমাজের উন্নয়ন অনেকাংশে এর উপর
                নির্ভর করে। এটি কিছু আন্তঃসংযুক্ত পর্যায়ের মাধ্যমে তৈরি করা
                হয়। প্রথমে বাঁশ কেটে গুঁড়ো করা হয়। তারপর তারা পরিষ্কার এবং
                ছোট টুকরা করা হয়। এর পরে, টুকরোগুলিকে একটি বড় মেশিনে মাটিতে
                মেখে সজ্জা তৈরি করা হয়। পরে একে নরম ও সাদা করার জন্য রাসায়নিক
                যোগ করা হয়। এখন নরম পাল্পটি চাদরে গড়িয়ে দেওয়া হয়। তারপরে,
                কাগজের শীটে রঙ যুক্ত করা হয়। অবশেষে, শীটগুলি শুকিয়ে আকারে কাটা
                হয়। কাগজ এখন ব্যবহারের জন্য প্রস্তুত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2a9a08" size="4">
                  <b>PROCESSING OF ORANGE SYRUP</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                The processing of orange syrup passes through various stages. At
                first ripe oranges are bought from the market. Good oranges are
                sorted and the rotten ones are left out. Then the good oranges
                are cleaned and peeled. They are crushed in a big bowl to
                extract juice. The juice is now strained to remove the seeds
                away with a piece of cloth or net. Sugar and syrup are then
                added to sweeten it. The syrup is now ready for drinking. Then
                the juice is poured in sterilised bottles. They are now sealed
                and labelled. Finally, they are put into boxes to despatch to
                shops for marketing.
                <br />
                <br />
                কমলা সিরাপ প্রক্রিয়াকরণ বিভিন্ন পর্যায়ের মধ্য দিয়ে যায়।
                প্রথমে পাকা কমলা বাজার থেকে কেনা হয়। ভাল কমলা বাছাই করা হয় এবং
                পচাগুলি বাদ দেওয়া হয়। তারপর ভাল কমলা পরিষ্কার এবং খোসা হয়। রস
                আহরণের জন্য একটি বড় পাত্রে এগুলি গুঁড়ো করা হয়। কাপড়ের টুকরো
                বা নেট দিয়ে বীজগুলিকে সরিয়ে ফেলার জন্য এখন রসটি ছেঁকে নেওয়া
                হয়। চিনি এবং সিরাপ তারপর এটি মিষ্টি যোগ করা হয়। সিরাপ এখন পান
                করার জন্য প্রস্তুত। তারপর রস জীবাণুমুক্ত বোতলে ঢেলে দেওয়া হয়।
                তারা এখন সিল এবং লেবেল করা হয়. পরিশেষে, সেগুলোকে বিপণনের জন্য
                দোকানে পাঠানোর জন্য বাক্সে রাখা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2a9a08" size="4">
                  <b>PUBLICATION OF SCHOOL MAGAZINE</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                To get out of boredom, a student needs something different from
                their fixed syllabus and a school magazine is a perfect one for
                them. Also it gives students a scope to express and enhance
                their writing creativity. For publication of the magazine, at
                first few students and teachers are selected to form an
                editorial body. Then the manuscripts of story, poem, essay etc
                are asked from students and teachers. The good writings are
                selected and corrected by the editorial body and they are sent
                to the press for printing. Also a name and cover page are
                selected for the magazine. Then the selected manuscripts are
                printed and bound into a magazine. At last, a final proof
                checking is done before printing required number of copies.
                Magazines are then distributed among students and teachers.
                <br />
                <br />
                একঘেয়েমি থেকে বেরিয়ে আসার জন্য, একজন শিক্ষার্থীর তাদের
                নির্দিষ্ট সিলেবাস থেকে আলাদা কিছু প্রয়োজন এবং একটি স্কুল
                ম্যাগাজিন তাদের জন্য উপযুক্ত। এছাড়াও এটি শিক্ষার্থীদের তাদের
                লেখার সৃজনশীলতা প্রকাশ এবং উন্নত করার সুযোগ দেয়। ম্যাগাজিন
                প্রকাশের জন্য, প্রথমে কিছু ছাত্র এবং শিক্ষককে একটি সম্পাদকীয়
                সংস্থা গঠন করার জন্য নির্বাচিত করা হয়। এরপর ছাত্র ও শিক্ষকদের
                কাছ থেকে গল্প, কবিতা, প্রবন্ধ ইত্যাদির পাণ্ডুলিপি চাওয়া হয়।
                ভাল লেখাগুলি সম্পাদকীয় সংস্থা দ্বারা নির্বাচিত এবং সংশোধন করা
                হয় এবং সেগুলি ছাপার জন্য প্রেসে পাঠানো হয়। এছাড়াও ম্যাগাজিনের
                জন্য একটি নাম এবং কভার পেজ নির্বাচন করা হয়। তারপর নির্বাচিত
                পাণ্ডুলিপিগুলি ছাপা হয় এবং একটি পত্রিকায় আবদ্ধ করা হয়। শেষ
                পর্যন্ত, প্রয়োজনীয় সংখ্যক কপি প্রিন্ট করার আগে একটি চূড়ান্ত
                প্রমাণ পরীক্ষা করা হয়। এরপর ছাত্র ও শিক্ষকদের মধ্যে পত্রিকা
                বিতরণ করা হয়।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2a9a08" size="4">
                  <b>HOW TO CONSTRUCT A BUILDING / HOUSE</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Building or house is very much necessary to lead a life.
                Everyone has a dream to construct his or her own dream house. It
                is constructed through a plan sanctioned by an architect. Then,
                materials like brick, sand, cement, rod, stone etc. are bought
                from the market. Mansions are hired to set up the house. After
                that, foundation and wall are constructed. Also, the roof is
                concreted over the walls. When the rooms are ready, doors and
                windows are set. Then, the walls are plastered and coloured. At
                last, electric and water connections are made where these are
                needed. Now the house is ready for living.
                <br />
                <br />
                জীবন যাপনের জন্য দালান বা বাড়ি খুবই প্রয়োজন। প্রত্যেকেরই তার
                নিজের স্বপ্নের বাড়ি তৈরি করার স্বপ্ন থাকে। এটি একটি স্থপতি
                দ্বারা অনুমোদিত একটি পরিকল্পনা মাধ্যমে নির্মিত হয়. তারপর বাজার
                থেকে ইট, বালি, সিমেন্ট, রড, পাথর ইত্যাদি উপকরণ কেনা হয়। বাড়ি
                স্থাপনের জন্য ম্যানশন ভাড়া করা হয়। এর পরে, ভিত্তি এবং প্রাচীর
                নির্মাণ করা হয়। এছাড়াও, ছাদ দেয়াল উপর জমাটবদ্ধ হয়। কক্ষ
                প্রস্তুত হলে, দরজা এবং জানালা সেট করা হয়। তারপর, দেয়াল
                প্লাস্টার এবং রঙিন করা হয়। সবশেষে যেখানে প্রয়োজন সেখানে
                বৈদ্যুতিক ও পানি সংযোগ করা হয়। এখন বাড়িটি বসবাসের জন্য
                প্রস্তুত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2a9a08" size="4">
                  <b>PROCESSING OF MUSTARD OIL</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Mustard oil is a very common ingredient used for cooking our
                daily food, fish, meat, vegetables etc. It is produced in the
                following processes. At first Mustard seeds are collected from
                the market. Then they are properly cleaned and dried in the sun.
                Dried seeds are put in a big bowl for crushing. A little water
                is added to it. Then the oil is extracted in the machine. The
                extracted oil is then strained with a piece of cloth and oil
                cakes are also separated from oil. Lastly oil is collected in
                containers and sealed. Oil is now ready for use and despatching
                to shops.
                <br />
                <br />
                সরিষার তেল আমাদের দৈনন্দিন খাবার, মাছ, মাংস, শাকসবজি ইত্যাদি
                রান্নার জন্য ব্যবহৃত একটি অতি সাধারণ উপাদান। এটি নিম্নলিখিত
                প্রক্রিয়ায় উত্পাদিত হয়। প্রথমে বাজার থেকে সরিষা সংগ্রহ করা
                হয়। তারপর সেগুলো ভালোভাবে পরিষ্কার করে রোদে শুকানো হয়। শুকনো
                বীজ গুঁড়ো করার জন্য একটি বড় পাত্রে রাখা হয়। এতে সামান্য পানি
                যোগ করা হয়। তারপর মেশিনে তেল বের করা হয়। উত্তোলিত তেল তারপর
                কাপড়ের টুকরো দিয়ে ছেঁকে নেওয়া হয় এবং তেলের কেকগুলিও তেল থেকে
                আলাদা করা হয়। সবশেষে পাত্রে তেল সংগ্রহ করে সিল করা হয়। তেল এখন
                ব্যবহারের জন্য এবং দোকানে পাঠানোর জন্য প্রস্তুত।
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#2a9a08" size="4">
                  <b>ORS PREPARATION</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                ORS or a mixture of dehydration can be prepared at home very
                easily. If a patient is dehydrated by some diseased condition,
                ORS must be provided. During preparation, hands should be washed
                and cleaned properly. It is prepared through a few simple steps.
                At first, the pots, glass and spoon are to be properly cleaned.
                In one litre water six teaspoons of sugar and half teaspoons of
                common salt should be added. Then it stirred well by the spoon.
                Now the ORS is ready to serve. It is now poured into clean glass
                and served. A dehydrated patient can consume it. Any healthy
                person can also consume it at summer time to keep him fit.
                <br />
                <br />
                ওআরএস বা ডিহাইড্রেশনের মিশ্রণ খুব সহজেই ঘরেই তৈরি করা যায়। কোনো
                রোগীর কোনো রোগের কারণে পানিশূন্য হলে, ওআরএস দিতে হবে। প্রস্তুতির
                সময়, হাত ভালভাবে ধুয়ে পরিষ্কার করা উচিত। এটি কয়েকটি সহজ ধাপের
                মাধ্যমে প্রস্তুত করা হয়। প্রথমে পাত্র, গ্লাস ও চামচ ভালোভাবে
                পরিষ্কার করতে হবে। এক লিটার পানিতে ছয় চা চামচ চিনি ও আধা চা
                চামচ সাধারণ লবণ মেশাতে হবে। তারপর চামচ দিয়ে ভালো করে নাড়তে
                হবে। এখন ওআরএস পরিবেশনের জন্য প্রস্তুত। এটি এখন পরিষ্কার গ্লাসে
                ঢেলে পরিবেশন করা হয়। ডিহাইড্রেটেড রোগী এটি খেতে পারেন। যে কোনো
                সুস্থ মানুষও তাকে ফিট রাখতে গ্রীষ্মকালে এটি খেতে পারেন।
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      {/* <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a> */}
    </div>
  );
}

ProcessingFlowChart.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(ProcessingFlowChart);
