import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";

import Colors from "../../utils/Colors";

function PrivacyPolicy() {
  return (
    <div className="App">
      <Helmet>
        <title>GK & Current Affairs Quiz Prep Privacy Policy</title>
        <meta name="description" content="GK & Current Affairs Quiz Prep Privacy Policy" />
        <meta name="keywords" content="GK & Current Affairs Quiz Prep Privacy Policy" />
      </Helmet>
      <header className="App-header">
        <p>
          {"\n"}Privacy Policy{"\n"}
        </p>
      </header>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Last updated: [25-09-2024]{"\n"}
      </div>
      <br />
      <div style={{ fontSize: 15, color: Colors.black }}>
        Team Samanta built the GK & Current Affairs Quiz Prep app as a Free app. This
        SERVICE is provided by Team Samanta at no cost and is intended for use
        as is. This page informs visitors regarding my policies regarding the
        collection, use, and disclosure of Personal Information if anyone
        decides to use my Service. If you choose to use my Service, then you
        agree to the collection and use of information about this policy. The
        Personal Information we collect is used to provide and improve the
        Service. I will not use or share your information with anyone except as
        described in this Privacy Policy. The terms used in this Privacy Policy
        have the same meanings as in our Terms and Conditions, which are
        accessible at GK & Current Affairs Quiz Prep unless otherwise defined in this
        Privacy Policy.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Information Collection and Use
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        GK & Current Affairs Quiz Prep does not collect any personal data or information
        from users of our mobile application. Since we do not collect any data,
        we do not share any user information with third parties or use it for
        any purposes. We do not use any tracking technologies or cookies to
        monitor user activity within our App. We may, however, collect
        non-personal information such as App usage statistics to monitor and
        improve the performance of our App. This information is collected
        anonymously and cannot be used to identify individual users. By using
        our App, you agree that we do not collect any data from you and are not
        responsible for any data collection or use by third-party services or
        websites that may be accessed through our App. Please note that this
        policy may be subject to change as we update our App, and we recommend
        reviewing it periodically for any updates.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Cookies
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        We do not use cookies to collect information about you or your usage of
        our App.
      </div>

      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Children's Privacy
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Our mobile app is intended for users who are 18 years of age and older.
        We do not knowingly collect personal information from children under the
        age of 18. {"\n"}
        If we become aware that we have collected personal information from a
        child under the age of 18, we will take steps to remove that information
        from our servers. {"\n"}We may collect personal information from users
        who are 18 years of age and older, including names, email addresses, and
        other information that you choose to provide to us. We use this
        information to provide you with our app's features and services, to
        communicate with you about your account or our app's features and
        services, and to personalize your experience with our app.
        {"\n"}We do not share any personal information about our users with
        third parties, except as necessary to provide our app's features and
        services or as required by law. We may use third-party service providers
        to help us operate our app and provide our services, and these service
        providers may have access to your personal information only to perform
        services on our behalf.{"\n"} We take reasonable measures to protect
        your personal information from unauthorized access, disclosure,
        alteration, or destruction. However, we cannot guarantee the security of
        your personal information.{"\n"} You may access, update, or delete your
        personal information by contacting us at nareshchandrasmt@gmail.com. We
        will respond to your request within a reasonable timeframe.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Advertising
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Ads in our app/service are age-appropriate and do not contain any
        deceptive or inappropriate content. We clearly disclose any ads or
        sponsored content.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        APIs and SDKs
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        Our app uses APIs and SDKs to provide enhanced functionality. We may use
        APIs to allow our app to communicate with other apps or services, such
        as social media platforms. We may also use SDKs to enable features such
        as analytics or advertising.{"\n"}We understand that the use of APIs and
        SDKs in an app designed for children requires special attention to
        privacy. Therefore, we only use APIs and SDKs from reputable providers
        that comply with privacy laws and regulations, including the Children's
        Online Privacy Protection Act (COPPA) in the United States.{"\n"}
        However, our app does not collect any personal information or usage data
        through APIs or SDKs. Any data that is collected through APIs or SDKs is
        used solely for the purpose of improving the app and providing a better
        experience for children.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Changes to This Privacy Policy
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page.
      </div>
      <div style={{ fontSize: 20, fontWeight: "bold", color: Colors.black }}>
        Contact Us
      </div>
      <div style={{ fontSize: 15, color: Colors.black }}>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at qlistthequestionbank@gmail.com.
      </div>
    </div>
  );
}

export default PrivacyPolicy;
