import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const EmailWriting = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>Email Writing Guide | Tips for Effective & Professional Emails</title>
        <meta name="description" content="Learn how to write professional and effective emails with our email writing guide. Improve communication with proper formatting, tone, and etiquette." />
        <meta name="keywords" content="email writing, professional emails, business communication, email etiquette, writing tips, formal emails, email structure" />
        <meta name="author" content="QList" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="distribution" content="global" />
        <meta property="og:title" content="Email Writing Guide | Tips for Effective & Professional Emails" />
        <meta property="og:description" content="Master email writing with expert tips on structuring, formatting, and improving communication for professional and business emails." />
        <meta property="og:url" content="https://www.qlist.co.in/email-writing-guide" />
        <meta property="og:type" content="article" />
        <meta name="twitter:title" content="Email Writing Tips | Improve Your Professional Communication" />
        <meta name="twitter:description" content="Enhance your email writing skills with expert tips on structure, tone, and etiquette for effective communication." />
        <link rel="canonical" href="https://www.qlist.co.in/email-writing-guide" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Email Writing
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100"></div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                
              </h6>
              <h1 className="mb-4">Welcome to Email Writing</h1>
              <p className="mb-4">
              Email writing is a modern and efficient way of communication used for both formal and informal purposes. A well-structured email typically includes a subject line, greeting, body, closing, and signature to ensure clarity and professionalism. Formal emails are used for business, official communication, job applications, and inquiries, while informal emails are for personal conversations. A clear and concise message, proper etiquette, and correct grammar make emails effective and impactful in professional and personal interactions.</p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
<p className="mb-0">
  <i className="fa fa-arrow-right text-primary me-2"></i>
  <a
    href="javascript:void(0)"
    onClick={() =>
      navigate(
        '/email-writing-introduction'
      )
    }
  >
    Introduction
  </a>
</p>
</div><div className="col-sm-6">
<p className="mb-0">
  <i className="fa fa-arrow-right text-primary me-2"></i>
  <a
    href="javascript:void(0)"
    onClick={() =>
      navigate(
        '/formal-business-emails'
      )
    }
  >
    Formal Business Emails
  </a>
</p>
</div><div className="col-sm-6">
<p className="mb-0">
  <i className="fa fa-arrow-right text-primary me-2"></i>
  <a
    href="javascript:void(0)"
    onClick={() =>
      navigate(
        '/job-application-emails'
      )
    }
  >
    Job Application Emails
  </a>
</p>
</div><div className="col-sm-6">
<p className="mb-0">
  <i className="fa fa-arrow-right text-primary me-2"></i>
  <a
    href="javascript:void(0)"
    onClick={() =>
      navigate(
        '/networking-emails'
      )
    }
  >
    Networking Emails
  </a>
</p>
</div><div className="col-sm-6">
<p className="mb-0">
  <i className="fa fa-arrow-right text-primary me-2"></i>
  <a
    href="javascript:void(0)"
    onClick={() =>
      navigate(
        '/follow-up-thank-you-emails'
      )
    }
  >
    Follow-up and Thank You Emails
  </a>
</p>
</div>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EmailWriting.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(EmailWriting);
