import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";

export function Paragraph() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>Paragraph</title>
        <meta name="description" content="Paragraph" />
        <meta name="keywords" content="Paragraph" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Paragraph
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      // href="https://play.google.com/store/apps/details?id=com.isbacbse"
                      // className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <div>
                <font color="#ef0b0b" size="4">
                  <b>P</b>
                </font>
                <font color="#37a0e5" size="4">
                  <b>A</b>
                </font>
                <font color="#f626d7" size="4">
                  <b>R</b>
                </font>
                <font color="#2b9a09" size="4">
                  <b>A</b>
                </font>
                <font color="#02a98a" size="4">
                  <b>G</b>
                </font>
                <font color="#ff9900" size="4">
                  <b>R</b>
                </font>
                <font color="#ef0b0b" size="4">
                  <b>A</b>
                </font>
                <font color="#87099a" size="4">
                  <b>P</b>
                </font>
                <font color="#f626d7" size="4">
                  <b>H</b>
                </font>{" "}
                <font color="#2b9a09" size="4">
                  <b>W</b>
                </font>
                <font color="#ff9900" size="4">
                  <b>R</b>
                </font>
                <font color="#87099a" size="4">
                  <b>I</b>
                </font>
                <font color="#ef0b0b" size="4">
                  <b>T</b>
                </font>
                <font color="#ef0b0b" size="4">
                  <b>I</b>
                </font>
                <font color="#37a0e5" size="4">
                  <b>N</b>
                </font>
                <font color="#f626d7" size="4">
                  <b>G</b>
                </font>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#3c92e5">
                1. Uses and Abuses of Mobile Phones.******
                <br />
                2. Safe Drive, Save life.**** <br />
                3. Kanyashree *****
                <br />
                4. Corona /Covid-19 ****
                <br />
                5. Usefulness of Morning Walk / Early Rising/Physical
                Exercise****
                <br />
                6. Advantage & Disadvantage of Online Class / E-learning
                <br />
                7. Importance of Yoga.
                <br />
                8. A book You Have Recently Read /A Film You Have recently Seen{" "}
                <br />
                9. The Person You Like Most / Your Favourite Teacher and Guider{" "}
                <br />
                10. A Short Journey / Visit to a Place of Interest.
                <br />
                11. A Memorable Day / Incident in Your Life.*******
                <br />
                12 Obedience****
                <br />
                13. Unity ****
                <br />
                16. Nirmal Bangla.
                <br />
                17. Immature Girl Child Marriage.
                <br />
                18. Plastic Pollution.
                <br />
                19. Your Hobby / Pet / Home / Parents / Aim <br />
                20. Flood Affected West Bengal
                <br />
                21. A journey by Train / Bus/ Foot – Walk <br />
                22. Amphan***
                <br />
                23. Sobuj Sathi <br />
                24. Duare Sarkar ****
                <br />
                25. Keep Your School Clean/Swachh Bharat Abhiyan
                <br />
                26. Hand Sanitizer
                <br />
                27. Man's Progress in Science & Technology. <br />
                28. Visit to a place of historical interest
                <br />
                29. Celebration of Independence Day in your school
                <br />
                30. The importance of the school library
                <br />
                31. Discipline in school life <br />
                32. Duties of students <br />
                34. Mid-day meal
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>Save Drive and Save Life</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                The phrase "Save Drive, Save Life" emphasizes the importance of
                responsible driving and the need to adhere to traffic rules and
                regulations. Irresponsible driving can lead to accidents,
                injuries, and loss of life. It is essential to follow traffic
                rules, drive at a safe speed, and avoid distractions like mobile
                phones or other gadgets while driving. Drivers must also ensure
                that their vehicles are well-maintained and roadworthy to
                prevent accidents due to technical failures. Pedestrians and
                other road users must also follow traffic rules and be aware of
                their surroundings while on the road. By adopting safe driving
                practices, we can prevent accidents and ensure the safety of
                ourselves and others on the road. Therefore, it is vital to
                promote and practice safe driving habits to help reduce
                accidents and save lives.
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>Kanyashree Prakalpa</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Kanyashree Prakalpa is a welfare scheme launched by the
                Government of West Bengal in India to improve the status and
                well-being of adolescent girls. The scheme was launched in 2013
                and is aimed at promoting education and preventing early
                marriage among girls. Under this scheme, girls between the ages
                of 13 and 18 are eligible to receive an annual scholarship of
                Rs. 750, provided they are enrolled in school and meet certain
                criteria related to attendance, performance, and other
                socio-economic factors. In addition, one-time grants are
                provided to girls from economically disadvantaged families. The
                scheme has been successful in reducing the school dropout rate
                among girls and improving their health and socio-economic
                status. It has also been recognized by the United Nations as a
                best practice model for empowering girls and promoting gender
                equality.
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>Covid -19 careness</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                COVID-19 is a highly infectious disease caused by the SARS-CoV-2
                virus. To prevent the spread of COVID-19, it is important to be
                aware of how the virus spreads and take necessary precautions.
                Basic measures like washing hands frequently with soap and
                water, wearing masks in public places, maintaining physical
                distance from others, avoiding large gatherings, and staying
                home if you feel unwell can go a long way in preventing the
                spread of the virus. It is also important to stay informed about
                the latest guidelines and recommendations from health
                authorities and follow them diligently. COVID-19 careness
                includes not only protecting oneself but also looking out for
                the vulnerable population and following public health guidelines
                to reduce the transmission of the virus. By taking appropriate
                measures to prevent the spread of COVID-19, we can protect
                ourselves and those around us and help bring an end to this
                pandemic.
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>Importance of early rising</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Early rising is an important habit that can have a positive
                impact on one's physical and mental well-being. It refers to
                waking up early in the morning, typically before the sun rises.
                There are several benefits to adopting this habit, including
                improved productivity, better sleep quality, and a healthier
                lifestyle. People who want to learn and stay ahead of the world
                get up early when every other person is sleeping. They learn
                various things and study in order to get knowledge as there are
                no distractions or disturbances in the morning. It is the ideal
                time to meditate, and do yoga or exercises. People who are
                concerned about their health wake up early to take a walk in the
                fresh air. This habit keeps them fit and healthy and prevents
                various illnesses. Early rising keeps one fresh by relieving
                their stress and tension which makes the person more productive
                and energetic. It is a great habit because a person can focus
                the best on his work in the morning. There is a famous English
                saying and it is considered a truth, “Early to bed and early to
                rise, makes a man healthy, wealthy and wise.” It is indeed the
                truth as getting up early sharpens our mind, makes us healthy
                and if the mind is healthy any person can become wealthy. One
                can experience nature’s beauty in the morning by seeing fresh
                flowers, cold breeze and rising sun. The atmosphere in the
                morning is quiet and beautiful. Early Rising has several
                positive impacts on the human body. That is the reason why it is
                considered to be a great habit and recommended by every
                successful person to get up early and get things done.
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>ADVANTAGE AND DISADVANTAGE OF ONLINE LEARNING</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Online learning, or e-learning, has become increasingly popular
                in recent years due to its convenience and accessibility. One of
                the main advantages of online learning is that it allows
                students to learn from anywhere at any time, as long as they
                have access to the internet. This flexibility allows students to
                balance their studies with work or family commitments, and also
                eliminates the need for commuting to a physical classroom.
                Online learning can also offer a wider range of courses and
                learning materials, and can be less expensive than traditional
                classroom learning.
                <br />
                <br />
                However, there are also some disadvantages to online learning.
                One of the main drawbacks is the lack of face-to-face
                interaction between students and instructors, which can result
                in a less engaging and interactive learning experience.
                Additionally, online learning may not be suitable for all
                learners, as it requires a high degree of self-discipline, time
                management skills, and motivation. Technical issues such as poor
                internet connectivity or computer problems can also affect the
                quality of the learning experience. In addition, some courses
                may not be able to provide practical training or hands-on
                experience, which can be crucial in certain fields of study.
                Overall, while online learning offers many advantages, it is
                important for students to carefully consider their individual
                needs and learning styles before choosing an online course or
                program.
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>Importance of Yoga in Modern Age</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Yoga is an ancient practice that has gained popularity in the
                modern age due to its many benefits for physical and mental
                well-being. In today's fast-paced and stressful world,
                practicing yoga can help individuals manage stress, anxiety, and
                depression, and improve overall mental health. Yoga also helps
                to improve physical fitness, flexibility, and balance, which can
                reduce the risk of injuries and improve posture. Additionally,
                yoga can help individuals cultivate mindfulness and focus, which
                are important for achieving personal and professional goals. By
                incorporating yoga into their daily routine, individuals can
                experience increased energy, reduced pain and stiffness, and
                improved sleep quality. Yoga can also be adapted to fit
                different lifestyles and physical abilities, making it
                accessible to people of all ages and fitness levels. The
                importance of yoga in the modern age lies in its ability to
                promote overall health and well-being, and provide individuals
                with the tools they need to lead happier, more fulfilling lives.
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>A Book You Have Recently Read</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Recently, I read the book "The Alchemist" by Paulo Coelho. The
                novel is a timeless masterpiece that tells the story of a young
                shepherd named Santiago who embarks on a journey of
                self-discovery and adventure in search of a treasure. The book
                takes the reader on a spiritual journey filled with life lessons
                and meaningful insights about following one's dreams and
                listening to the heart's desires. Coelho's storytelling is
                captivating, and he masterfully weaves together themes of
                destiny, purpose, and the power of the universe. The book's
                message encourages readers to pursue their dreams and overcome
                obstacles by remaining steadfast in their pursuit. The story
                also highlights the importance of appreciating the journey and
                the lessons learned along the way. Overall, "The Alchemist" is a
                thought-provoking and inspiring novel that has something to
                offer for readers of all ages and backgrounds.
              </font>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#f626d7" size="4">
                  <b>A Film You Have Recently Seen</b>
                </font>
              </div>
              <br />
              <font color="#3c92e5">
                Recently, I watched the movie "Minari" directed by Lee Isaac
                Chung. The film tells the story of a Korean-American family who
                moves to Arkansas in the 1980s to start a farm and pursue the
                American Dream. The movie is a beautifully crafted exploration
                of the immigrant experience and the complexities of family
                dynamics. The performances by the cast, especially by Steven
                Yeun and Youn Yuh-jung, are outstanding, bringing a sense of
                authenticity and depth to the characters. The cinematography is
                also stunning, capturing the rural landscape and the daily
                struggles of farming life. What makes "Minari" so powerful is
                its ability to convey the challenges and joys of pursuing one's
                dreams, while still grappling with cultural identity and family
                ties. The film is a poignant reminder of the resilience and
                strength of the human spirit and the importance of holding on to
                hope in the face of adversity. Overall, "Minari" is a must-see
                film that tells a universal story of love, sacrifice, and the
                pursuit of happiness.
              </font>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      {/* <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a> */}
    </div>
  );
}

Paragraph.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(Paragraph);
