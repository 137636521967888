import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";

export function Report() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>Report</title>
        <meta name="description" content="Report" />
        <meta name="keywords" content="Report" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Report
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      // href="https://play.google.com/store/apps/details?id=com.isbacbse"
                      // className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <b>
                <font size="4" color="#f829c0">
                  REPORT WRITING&nbsp;
                </font>
              </b>
            </div>
            <br />
            <br />
            <div>
              <span>
                <b>
                  <font color="#f925d8">
                    1. Write a report on a campaign on 'Educate the Girl Child'
                    organized by your school.
                  </font>
                </b>
              </span>
            </div>
            <div>
              <span>
                <b>
                  <font color="#f925d8">
                    <br />
                  </font>
                </b>
              </span>
            </div>
            <div>
              <span>
                <b>
                  <font color="#f925d8">'Educate the Girl Child' Campaign</font>
                </b>
              </span>
            </div>
            <div>
              <span>
                <b>
                  <font color="#f925d8">Reported by (your name)</font>
                </b>
              </span>
            </div>
            <div>
              <span>
                <font color="#0089e0">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <font color="#0089e0">
                ABC school, Ambala Haryana, 13th March 2017 : A campaign on
                education of girl child was organized in our school on 14th
                March which is marked as women’s day. The school conducted a
                rally that marched through the neighbouring locality with
                posters and placards. All the students, teachers and
                non-teaching staff participated in the rally to create awareness
                about girl child education, how it benefits the society and why
                it is crucial for the development of our society. The programme
                included cultural competition, seminar and staging a one-act
                play highlighting the issue. It says that while a man can
                educate himself, a well-educated woman has the ability to
                improve and teach the whole family. Almost half of the world's
                population is women, and their education is just as important as
                a man's education. The program started at 9 am in the assembly
                where the headmaster gave a speech on how we should empower
                girls to get education and why girls drop out from schools. Our
                school promised to give free education to at least 50 girls each
                year to help the society become more educated.We also promised
                to assist girls if they need any help to continue their studies.
                Girl education is as important as food to every human and thus
                we should not keep them unaware of it. Girls have from time to
                time proved that they’re no less than boys but better and
                efficient at work places. To encourage this we all should come
                together and do as much as we can. I hope every school organizes
                such a campaign and helps girls. I want to express my gratitude
                to our school and the principal for arranging this fantastic
                campaign and making us aware of the important issue of girl
                child education.
              </font>
            </div>
            <br />
            <br />
            <div>
              <b>
                <font color="#f925d8">
                  2. Write a report on the celebration of Rabindra Jayanti.
                </font>
              </b>
              <div>
                <b>
                  <font color="#f925d8">
                    Rabindra Jayanti Celebration&nbsp;&nbsp;
                  </font>
                </b>
              </div>
              <div>
                <b>
                  <font color="#f925d8">
                    &nbsp;Reported by (নিজের নাম লিখবে)
                  </font>
                </b>
                <font color="#0089e0">&nbsp;&nbsp;</font>
              </div>
              <div>
                <font color="#0089e0">
                  <br />
                </font>
              </div>
              <div>
                <font color="#0089e0">
                  ABC High school, Bankura, 10th May 2024 : At our school, we
                  celebrate Rabindra Jayanti every year on the 25th day of the
                  Baisakh month to honor Rabindranath Tagore's birthday. This
                  celebration is not only in our school but is also observed
                  worldwide to appreciate Tagore's great contributions to
                  culture, literature, and poetry. The event included cultural
                  programs and speeches by special guests. The entire school
                  building was beautifully decorated, and a big picture of
                  Rabindranath was placed in front of the stage. Mr. Prasanta
                  Kumar Mahato, the local BDO, inaugurated the event by offering
                  flowers to Viswakabi. We had a poetry competition, dance, and
                  drama performances featuring Tagore's works. Our teacher
                  informed us that Tagore was the first non-European to win a
                  Nobel Prize in Literature in 1913. We received Tagore's books
                  and were encouraged to learn more about literature. The
                  program ended at 2:30 pm, and I'm thankful to our school and
                  the literary club for organizing such an informative event. I
                  hope more schools promote literature and culture through
                  similar programs. The programme made the students feel that
                  Rabindranath is still very much alive in the lives of the
                  Bengalis.
                </font>
              </div>
              <div>
                <br />
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#f925d8">
                <b>
                  3. Write a report on a farewell meeting of a retired teacher
                  of your school.&nbsp;
                </b>
              </font>
              <div>
                <font color="#f925d8">
                  <b>
                    <br />
                  </b>
                </font>
                <div>
                  <font color="#f925d8">
                    <b>Farewell Meeting of a Retired Teacher</b>
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    <b>Reported by (নিজের নাম লিখবে)</b>
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    XYZ HS, Hooghly, 6th January, 2024 :
                  </font>
                  <font color="#0089e0">
                    {" "}
                    The farewell ceremony of a retired teacher at our school was
                    an emotional and memorable event for all the students of ABC
                    High School. The students at our school arranged a farewell
                    ceremony for Mr. Samir Sen, the English teacher of the
                    school who has recently retired from her service. The event
                    was held in the school auditorium and was attended by the
                    school principal, teachers, and students. The hall was
                    beautifully decorated with flowers and paper. The papers
                    were carrying messages for Mr Sen like – ‘We will miss you
                    sir’, ‘You will always with us by your teachings’ and etc.
                  </font>
                </div>
                <div>
                  <font color="#0089e0">
                    The ceremony began with the headmaster delivering a
                    heartfelt speech in honor of the retiring teacher,
                    highlighting her contributions to the school and her years
                    of service. He also said that a regular, dutiful, diligent
                    and polite teacher and person like her is rare. The students
                    would run to her for any study problem and she would solve
                    them instantly. She became the students’ friend, philosopher
                    and guide at the same time. Even she would help so many
                    students with study materials for their poor financial
                    condition. The students paid their regards to Mrs Sen with
                    bouquets and gifts. Then Mrs Sen was invited on the stage to
                    say something. She first thanked all the students and
                    teachers for giving her so much love and respect. She added
                    in a trembling voice that he was having a hard time leaving
                    her favourite place, this school. She also added that she
                    always treated this school like her family and the students
                    like her children. In the end, she became so emotional that
                    she shed tears and blessed the students to shine in their
                    future. Then she gave chocolates and a pen to every student.
                    The programme came to an end with a Rabindrasangeet which
                    created an emotional atmosphere in the auditorium.
                  </font>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#f925d8">
                <b>
                  4. Write a report on the Safe Drive, Save Life traffic
                  awareness programme organized by school.&nbsp;
                </b>
              </font>
              <div>
                <font color="#f925d8">
                  <b>Safe Drive, Save Life&nbsp;</b>
                </font>
              </div>
              <div>
                <font color="#f925d8">
                  <b>Reported by (নিজের নাম লিখবে)</b>
                </font>
              </div>
              <div>
                <font color="#0089e0">
                  <br />
                </font>
              </div>
              <div>
                <font color="#f925d8">
                  Salt Lake, Kolkata, 14th May 2023 :{" "}
                </font>
                <font color="#0089e0">
                  Our school conducted a Safe Drive, Save Life traffic awareness
                  programme last Monday. In all hundred school students
                  participated in the programme that started at 11:30 am. and
                  concluded by 3:30 pm. I was fortunate enough to be a part of
                  the awareness programme that created a huge stir and interest
                  among the public of the locality. The students stood at
                  different locations across the town, dressed like traffic
                  police with caps on their heads, sticks in hands and whistles
                  in mouths. They regulated the movement of traffic asking and
                  instructing the drivers to drive their vehicles one by one
                  without crossing the stipulated speed limit. They were made to
                  stop at every traffic signal and it was ensured that nobody
                  would violate the existing traffic rules. The effort of the
                  students on the day ensured safe crossing of roads by the
                  public and pedestrians who were also made to abide by the
                  rules of the road. As a result, there was no accident
                  occurring nor any traffic jam. The modest effort of the school
                  students won the hearts of the public who lauded and
                  appreciated it.
                </font>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#f925d8">
                <b>
                  5. Your school observed the 'Kanyashree Divas' in your school.
                  Write a report on it for the local periodical magazine.
                </b>
              </font>
              <div>
                <font color="#f925d8">
                  <b>
                    <br />
                  </b>
                </font>
                <div>
                  <font color="#f925d8">
                    <b>Kanyashree Divas</b>
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    <b>Reported by (নিজের নাম লিখবে)</b>
                  </font>
                </div>
                <div>
                  <font color="#0089e0">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    Salt Lake, Kolkata, 14th August 2023 :
                  </font>
                  <font color="#0089e0">
                    {" "}
                    West Bengal Government observes Kanyashree Day every year on
                    14th August. Our school also observed Kanyashree Day
                    yesterday to mark the project “Kanyashree '' initiated by
                    Mamata Banerjee, the Chief Minister of West Bengal. This
                    Prakalpa is the ‘Brainchild’ of the Chief Minister of West
                    Bengal. To observe this special day the students of our
                    school arranged a gorgeous programme yesterday. This
                    programme was held in the school auditorium. The school
                    campus was nicely decorated. The girl students participated
                    actively in the programme. Our respected headmaster presided
                    over the programme.
                  </font>
                </div>
                <div>
                  <font color="#0089e0">
                    &nbsp;On that day the programme was started at 10:00 a.m.
                    and continued until 1 p.m. The students of class 8 to 12
                    participated in it including all the teachers and H.M of the
                    school. In the beginning the headmaster delivered a long
                    speech on the significance of the day. Then some girl
                    students delivered their speech about Kanyashree Prakalpa
                    and how they have been benefited by the scheme.
                  </font>
                </div>
                <div>
                  <font color="#0089e0">
                    Some students recited poems relating to the girls and the
                    impact of this scholarship on them. There was also a debate
                    competition on the subject “YES TO EDUCATION AND NO TO CHILD
                    MARRIAGE”. The debate was very impressive. Then some
                    teachers also gave speeches about Kanyashree Prakalpa, how
                    it has stopped the drop out of students from schools, and
                    how this Prakalpa helps girl students to continue their
                    higher education. The President of the programme handed nice
                    prizes to the three best competitors. There was also an
                    arrangement of refreshment at the end. This programme was
                    very inspiring.
                  </font>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#0089e0">&nbsp;</font>
              <font color="#f925d8">
                <b>
                  6. Write a newspaper report on a bus accident within 100
                  words.&nbsp;
                </b>
              </font>
              <div>
                <font color="#f925d8">
                  <b>
                    <br />
                  </b>
                </font>
                <div>
                  <font color="#f925d8">
                    <b>Bus Accident</b>
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    <b>Reported by (নিজের নাম লিখবে)</b>
                  </font>
                </div>
                <div>
                  <font color="#0089e0">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    On the morning of March 9, 2023 :{" "}
                  </font>
                  <font color="#0089e0">
                    A terrible bus accident happened in the early morning
                    yesterday. The accident took place on 4th June at 5:00 a.m.
                    The accident happened on the road between Kalipur – Arambagh
                    near Pallishree. 5 persons were seriously injured including
                    a child and a woman. Hearing a loud sound, local people
                    rushed to the spot.&nbsp;
                  </font>
                </div>
                <div>
                  <font color="#0089e0">
                    As soon as possible, they called a rescue team, along with
                    police and medical personnel, and rushed to the scene. The
                    injured passengers were extracted from the bus and taken to
                    nearby hospitals for medical attention. Unfortunately, seven
                    passengers lost their lives on the spot, and many others
                    suffered severe injuries. According to eyewitnesses, the bus
                    was traveling at a high speed and was trying to overtake a
                    vehicle when it hit the truck and flipped over onto its
                    side.
                  </font>
                </div>
                <div>
                  <font color="#0089e0">
                    The police investigated the cause of the accident. The
                    injured passengers are receiving medical attention and their
                    families have been informed of the situation.
                  </font>
                </div>
                <div>
                  <font color="#0089e0">
                    The incident highlights the importance of following traffic
                    rules and the need for proper rest and maintenance of
                    vehicles. The local government has promised to take
                    necessary steps to improve road safety and prevent such
                    accidents in the future.
                  </font>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <b>
                <font color="#f925d8">
                  7. Write a report on the Teachers' Day celebration in your
                  school.
                </font>
              </b>
              <div>
                <b>
                  <font color="#f925d8">
                    <br />
                  </font>
                </b>
                <div>
                  <b>
                    <font color="#f925d8">Teachers Day Celebration</font>
                  </b>
                </div>
                <div>
                  <b>
                    <font color="#f925d8">Reported By “Your Name”</font>
                  </b>
                </div>
                <div>
                  <font color="#f925d8">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    ABC High School, 5th September 2023 :
                  </font>
                  <font color="#0089e0">
                    {" "}
                    Teachers' Day is a special occasion celebrated in honor of
                    educators who play a crucial role in shaping the lives of
                    students. Teacher’s day celebrated on 5th September each
                    year on the occasion of the birth of Dr. Sarvepalli
                    Radhakrishnan who was known as an amazing teacher of his
                    time. Small function was organized in our school on this
                    occasion for our beloved teachers. The program started by
                    praying and singing the national anthem at 9:30 a.m. After
                    that, our headmaster, Vice-Principal and many teachers lit
                    the Diya lamp. The students performed various cultural
                    programs like singing, dancing, and reciting poems. A female
                    student announced all the events happening on stage one by
                    one through a mic. Local MLA also participated in our
                    program. A group of 8 students also performed a play on the
                    theme of Teacher’s Day. After that, a group of class 5
                    students sang several poems based on Teacher’s Day. After
                    that, the students gave a passionate speech on the theme of
                    Teacher’s Day where they explained the importance of
                    teachers and their heartfelt gratitude and appreciation for
                    their teachers for their hard work, dedication, and
                    invaluable contributions to their education and overall
                    development. Some other programs were also presented by the
                    students. On that day, senior students took on the
                    responsibility of teaching as a gratitude to our teachers.
                    All the teachers were given a decorated rose flower and
                    chocolate by the students. In the end, all the students
                    thank all the teachers for providing a great education.
                  </font>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#f925d8">
                <b>
                  8. Write a report about a Health Check up camp at your school.
                </b>
              </font>
              <div>
                <font color="#f925d8">
                  <b>or</b>
                </font>
              </div>
              <div>
                <font color="#f925d8">
                  <b>
                    Write a report on the Health Awareness Camp recently
                    organised in your school.
                  </b>
                </font>
              </div>
              <div>
                <font color="#f925d8">
                  <b>
                    <br />
                  </b>
                </font>
              </div>
              <div>
                <font color="#f925d8">
                  <b>Health Awareness Camp&nbsp;</b>
                </font>
              </div>
              <div>
                <font color="#f925d8">
                  <b>Reported by (নিজের নাম লিখবে)</b>
                </font>
              </div>
              <div>
                <font color="#f925d8">
                  <br />
                </font>
              </div>
              <div>
                <font color="#f925d8">ABC High School, 12 May 2023 : </font>
                <font color="#0089e0">
                  A Health Awareness Camp was recently organised in our school
                  on 25th March. The camp was organised in collaboration with a
                  local hospital and aimed at creating awareness about health
                  issues among students. The camp started with a talk on the
                  importance of maintaining good health by Dr. Anjali, a
                  renowned doctor from the hospital. She highlighted the
                  importance of a balanced diet, regular exercise, and hygiene
                  to maintain good health.&nbsp;
                </font>
              </div>
              <div>
                <font color="#0089e0">
                  The camp included free health checkups for all students, which
                  included measuring blood pressure, BMI, and eye checkups. The
                  doctors also conducted a dental checkup and provided advice on
                  maintaining dental hygiene. The highlight of the camp was the
                  interactive session where students could ask questions about
                  their health concerns and get advice from the doctors. The
                  doctors also provided information on various health issues
                  such as diabetes, hypertension, and obesity.
                </font>
              </div>
              <div>
                <font color="#0089e0">
                  Overall, the Health Awareness Camp was a great success, and it
                  provided students with valuable information on how to maintain
                  good health. The initiative taken by the school was
                  appreciated by parents and students alike, and we look forward
                  to more such initiatives in the future.
                </font>
              </div>
            </div>
            <br />
            <br />
            <div>
              <font color="#f925d8">
                <b>
                  9. Write a newspaper report on a train accident in
                  Odisha.***(Coromandel Express)
                </b>
              </font>
              <div>
                <font color="#f925d8">
                  <b>
                    <br />
                  </b>
                </font>
                <div>
                  <font color="#f925d8">
                    <b>Train Accident in Odisha</b>
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    <b>Reported by (নিজের নাম লিখবে)</b>
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    <br />
                  </font>
                </div>
                <div>
                  <font color="#f925d8">
                    Balasore, Odisha, 3rd June, 2023 :{" "}
                  </font>
                  <font color="#0089e0">
                    At least 290 people were killed and around 900 were injured
                    in a horrific three-train collision that involved the
                    Bangalore-Howrah Superfast Express, the Shalimar-Chennai
                    Central Coromandel Express and a goods train .The deadly
                    train accident occurred near the Bahanaga Bazar station in
                    Balasore district of Odisha at around 6:55 P.M on
                    02.06.2023.Being derailed , Bangalore-Howrah Superfast
                    Express and the Shalimar-Chennai Central Coromandel Express
                    hit a goods train. Many passengers were trapped in the
                    overturned coaches of the superfast train. According to
                    railway official, Coromandel Express was moving from Howrah
                    to Chennai ,and it rammed into derailed coaches of the other
                    train which was going from Bengaluru to Kolkata. Three NDRF
                    units, 4 Disaster Rapid Action Force units, over 15 fire
                    rescue teams ,30 doctors and 200 police started rescue
                    operation.Injured persons were taken to local hospital.Mr.
                    Vaishnaw, the railway minister, visited the spot and
                    expressed grief. Mr. Vaishnaw also announced compensation of
                    Rs 10 lakh for the family of the dead , Rs 2 lakh for those
                    seriously injured and Rs 50,000 for those who sustained
                    minor injuries. Prime Minister Narendra Modi also visited
                    the spot and expressed his distress over the accident. He
                    announced a compensation of Rs 2 lakh for the family of the
                    dead and Rs 50,000 for the injured.&nbsp;
                  </font>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      {/* <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a> */}
    </div>
  );
}

Report.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(Report);
