/**
 *
 * HomePage
 *
 */

import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";

import DrawerComponent from "../../components/DrawerComponent";
import CarouselComponent from "../../components/CarouselComponent/Loadable";
import ServiceComponent from "../../components/ServiceComponent/Loadable";
import AboutComponent from "../../components/AboutComponent/Loadable";
import FooterComponent from "../../components/FooterComponent/Loadable";
import OwlCarouselComponent from "../../components/OwlCarouselComponent/Loadable";
// import { useInjectSaga } from 'utils/injectSaga';
// import { useInjectReducer } from 'utils/injectReducer';
import makeSelectHomePage, {
  getTypesAndTopics,
  getCurrentChannels,
} from "./selectors";
import { setType, setTopic } from "./reducer";
import { fetchChnnelInfo, fetchPopulareChannels } from "./sagaActions";

export function HomePage({
  fetchPopulareChannelsInfo,
  allData,
  setPopularType,
  setPopularTopic,
  currentChannels,
}) {
  useEffect(() => {
    // When initial state username is not null, submit the form to load repos
    fetchPopulareChannelsInfo();
  }, []);
  return (
    <>
      <Helmet>
        <title>CBSE & West Bengal Board Guides | English Grammar & Writing Skills</title>
        <meta name="description" content="Find comprehensive study guides for CBSE and West Bengal Board classes, along with English grammar and writing skills resources to improve learning." />
        <meta name="keywords" content="CBSE guide, West Bengal Board guide, English grammar, writing skills, CBSE study material, WB board study guide, exam preparation, school curriculum" />
        <meta name="author" content="QList" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="distribution" content="global" />
        <meta property="og:title" content="CBSE & West Bengal Board Guides | English Grammar & Writing Skills" />
        <meta property="og:description" content="Explore CBSE and West Bengal Board study guides, English grammar lessons, and writing skills improvement resources for students." />
        <meta property="og:url" content="https://www.qlist.co.in" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="CBSE & West Bengal Board Study Guides | English Grammar & Writing Skills" />
        <meta name="twitter:description" content="Get CBSE and WB Board study materials, English grammar lessons, and writing skills guides to enhance learning and exam preparation." />
        <link rel="canonical" href="https://www.qlist.co.in" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Welcome to QList
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* <DrawerComponent
        allData={allData}
        setPopularType={setPopularType}
        setPopularTopic={setPopularTopic}
        currentChannels={currentChannels}
      /> */}
      {/* <CarouselComponent /> */}
      <ServiceComponent />
      <AboutComponent />
      {/* <FooterComponent /> */}
      {/* <OwlCarouselComponent /> */}
    </>
  );
}

// HomePage.propTypes = {
//   dispatch: PropTypes.func.isRequired,
// };

const mapStateToProps = createStructuredSelector({
  allData: getTypesAndTopics(),
  currentChannels: getCurrentChannels(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchPopulareChannelsInfo: (evt) => dispatch(fetchPopulareChannels()),
    setPopularType: (type) => dispatch(setType(type)),
    setPopularTopic: (topic) => dispatch(setTopic(topic)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(HomePage);
