import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const CbseClass8 = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>CBSE Class 8 – NCERT Solutions, Notes, MCQs, and Textbook Explanations</title>
        <meta name="title" content="CBSE Class 8 – NCERT Solutions, Notes, MCQs, and Textbook Explanations" />
        <meta name="description" content="Access all CBSE Class 8 resources including NCERT solutions, chapter-wise notes, MCQs, and textbook explanations. Perfect for homework help, quick revision, and exam preparation." />
        <meta name="keywords" content="CBSE Class 8, NCERT Solutions, Class 8 English, Class 8 Science, Class 8 Mathematics, CBSE Class 8 MCQs, Class 8 Notes, Textbook Explanations, QList" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="QList" />
        <meta name="language" content="English" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.qlist.co.in/cbse/class-8" />
        <meta property="og:title" content="CBSE Class 8 – NCERT Solutions, Notes, MCQs, and Textbook Explanations" />
        <meta property="og:description" content="Explore comprehensive CBSE Class 8 resources including NCERT solutions, notes, MCQs, and textbook explanations to excel in exams and improve your understanding." />
        <meta name="twitter:title" content="CBSE Class 8 – NCERT Solutions, Notes, MCQs, and Textbook Explanations" />
        <meta name="twitter:description" content="Get access to all CBSE Class 8 study resources, including NCERT solutions, chapter-wise notes, MCQs, and textbook explanations. Perfect for exam prep and revision." />
        <link rel="canonical" href="https://www.qlist.co.in/cbse/class-8" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                CBSE Class 8 Study Material, Notes, Syllabus & Resources
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      HOME
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      CBSE
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Class 8
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              // style="min-height: 400px;"
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src={require("../../img/about.jpg")}
                  alt=""
                  // style="object-fit: cover;"
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                CBSE Class 8
              </h6>
              <h1 className="mb-4">Welcome</h1>
              <p className="mb-4">CBSE Class 8 Study Material, Notes, Syllabus & Resources</p>
              <p className="mb-4">
              Comprehensive CBSE Class 8 Resources – NCERT Solutions, Notes, MCQs, and Textbook Explanations
              Explore our all-in-one CBSE Class 8 resource page with detailed NCERT solutions, chapter-wise notes, practice MCQs, and textbook explanations. Perfect for quick revision, homework help, and exam preparation. Enhance your understanding of all subjects with clear and concise explanations designed to make learning easy and effective.
              </p>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Notes
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Q&A
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Detailed Explanations
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    MCQs
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    NCERT Solutions
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Test Your Knowledge
                  </p>
                </div>
              </div>
              <a
                className="btn btn-primary py-3 px-5 mt-2"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.isbacbse"
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            {/* <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate("/English-CBSE-Class-10-English-Exam-Preparation-Guide")
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">English</h5>
                </div>
              </div>
            </div> */}
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate("/cbse/class-8/english/ncert-solutions/")
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">English <br/> NCERT Solutions</h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate(
                  "/cbse/class-8/english/mcqs"
                )
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">English <br/> MCQS</h5>
                </div>
              </div>
            </div>
            {/* <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate(
                  "/cbse/class-9/science/"
                )
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">Social Science <br/> Notes</h5>
                </div>
              </div>
            </div> */}
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate(
                  "/cbse/class-8/english/notes/"
                )
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">English <br/> Notes</h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate(
                  "/cbse/class-8/english/textbook-explanation/"
                )
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">English <br/> Textbook Explanation</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CbseClass8.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(CbseClass8);
