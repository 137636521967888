import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const CbseClass9 = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
      <title>CBSE Class 9 - NCERT Solutions, Notes, Syllabus & Sample Papers</title>
      <meta name="description" content="Access CBSE Class 9 NCERT solutions, chapter-wise notes, latest syllabus, sample papers, and important questions for all subjects. Perfect for exam preparation!"/>
      <meta name="keywords" content="CBSE Class 9, NCERT Solutions Class 9, Class 9 Notes, CBSE Syllabus 2025, Class 9 Sample Papers, Class 9 Important Questions, Class 9 Science, SST, English"/>
      <meta name="author" content="QList"/>
      <meta property="og:title" content="CBSE Class 9 - NCERT Solutions, Notes, Syllabus & Sample Papers"/>
      <meta property="og:description" content="Comprehensive CBSE Class 9 study material including NCERT solutions, notes, sample papers, and more. Learn smarter with QList."/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://www.qlist.co.in/cbse/class-9/"/>
      <meta name="twitter:title" content="CBSE Class 9 - NCERT Solutions, Notes, Syllabus & Sample Papers"/>
      <meta name="twitter:description" content="Study all CBSE Class 9 subjects with NCERT solutions, notes, syllabus updates, and exam resources from QList."/>
      <link rel="canonical" href="https://www.qlist.co.in/cbse/class-9/"/>
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                CBSE Class 9 Study Material, Notes, Syllabus & Resources
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      HOME
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      CBSE
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Class 9
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              // style="min-height: 400px;"
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src={require("../../img/about.jpg")}
                  alt=""
                  // style="object-fit: cover;"
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                CLASS 9
              </h6>
              <h1 className="mb-4">Welcome</h1>
              <p className="mb-4">CBSE Class 9 Study Material, Notes, Syllabus & Resources</p>
              <p className="mb-4">
                Welcome to your one-stop destination for all CBSE Class 9 study resources! Whether you're looking for updated syllabus, detailed chapter-wise notes, NCERT solutions, sample papers, or important questions, we’ve got you covered. Our platform is designed to help students learn efficiently and excel in their exams with well-organized and easy-to-understand content. Stay ahead with regular updates, expert tips, and downloadable resources – all aligned with the latest CBSE curriculum.
              </p>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Notes
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Q&A
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Detailed Explanations
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    MCQs
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Exam Success
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Test Your Knowledge
                  </p>
                </div>
              </div>
              <a
                className="btn btn-primary py-3 px-5 mt-2"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.isbacbse"
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            {/* <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate("/English-CBSE-Class-10-English-Exam-Preparation-Guide")
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">English</h5>
                </div>
              </div>
            </div> */}
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate("/cbse/class-9/social-science/")
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">Social Science <br/> NCERT Solutions</h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate(
                  "/cbse/class-9/science/"
                )
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">Science <br/> NCERT Solutions</h5>
                </div>
              </div>
            </div>
            {/* <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate(
                  "/cbse/class-9/science/"
                )
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">Social Science <br/> Notes</h5>
                </div>
              </div>
            </div> */}
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate(
                  "/cbse/class-9/science/notes/"
                )
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">Science <br/> Notes</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CbseClass9.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(CbseClass9);
