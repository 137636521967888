import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const Noticewriting = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>Notice Writing | Format, Examples, and Tips for Effective Notices</title>
        <meta name="description" content="Learn how to write an effective notice with proper format, examples, and writing tips. Perfect for schools, offices, and public announcements." />
        <meta name="keywords" content="notice writing, notice format, notice writing examples, formal notices, school notice, public notice, office notice, announcement writing" />
        <meta name="author" content="QList" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="distribution" content="global" />
        <meta property="og:title" content="Notice Writing | Format, Examples, and Tips for Effective Notices" />
        <meta property="og:description" content="Master notice writing with expert guidelines on format, structure, and content. Ideal for schools, workplaces, and official announcements." />
        <meta property="og:url" content="https://www.qlist.co.in/notice-writing" />
        <meta property="og:type" content="article" />
        <meta name="twitter:title" content="Notice Writing | Format & Examples for Official Announcements" />
        <meta name="twitter:description" content="Discover the correct notice writing format with examples and tips for drafting clear and professional notices for various purposes." />
        <link rel="canonical" href="https://www.qlist.co.in/notice-writing" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Notice writing
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100"></div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                
              </h6>
              <h1 className="mb-4">Welcome to Notice writing</h1>
              <p className="mb-4">
              Notice writing is a formal way of communicating important information to a specific audience, typically displayed in schools, offices, public places, or newspapers. A notice is concise, clear, and to the point, covering key details such as what, when, where, and who is involved. It follows a structured format, including a heading, date, issuing authority, subject, body, and signature. Notices are used for announcements, events, meetings, or important instructions, ensuring effective and efficient communication.</p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
<p className="mb-0">
  <i className="fa fa-arrow-right text-primary me-2"></i>
  <a
    href="javascript:void(0)"
    onClick={() =>
      navigate(
        '/notice-writing-introduction'
      )
    }
  >
    INTRODUCTION 
  </a>
</p>
</div><div className="col-sm-6">
<p className="mb-0">
  <i className="fa fa-arrow-right text-primary me-2"></i>
  <a
    href="javascript:void(0)"
    onClick={() =>
      navigate(
        '/all-types-of-notice-writing'
      )
    }
  >
    ALL NOTICE WRITING 
  </a>
</p>
</div>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Noticewriting.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(Noticewriting);
