import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { compose } from "redux";

export function NOTICEWRITING() {
  const [isNotes, setIsNotes] = useState(true);

  return (
    <div>
      <Helmet>
        <title>NOTICE WRITING</title>
        <meta name="description" content="NOTICE WRITING" />
        <meta name="keywords" content="NOTICE WRITING" />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                NOTICE WRITING
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Notes
            </h6>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-1.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(false)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      MCQ
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-2.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      onClick={() => setIsNotes(true)}
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      NOTES
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="course-item bg-light">
                <div className="position-relative overflow-hidden">
                  <img
                    className="img-fluid"
                    src={require("../../../../img/course-3.jpg")}
                    alt=""
                  />
                  <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                    <a
                      target="_blank"
                      // href="https://play.google.com/store/apps/details?id=com.isbacbse"
                      // className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      PRACTICE / WORKSHEETS
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isNotes && (
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-12"></div>
          <div></div>
        </div>
      )}
      {isNotes && (
        <div className="col-lg-12">
          <div className="col-lg-12">
            <div>
              <b>
                <font size="4" color="#ff2cc4">
                  NOTICE WRITING&nbsp;
                </font>
              </b>
            </div>
            <br />
            <br />
            <div>
              <div>
                <font color="#0d89c5">
                  <br />
                </font>
              </div>
              <span>
                <div>
                  <span>
                    <font color="#0d89c5">
                      1. Suppose you are the secretary of ABC CLUB. Your club is
                      going to organise a blood donation camp in your club
                      premises. Write a notice inviting the people of your
                      locality for donate blood in a blood donation camp.
                    </font>
                  </span>
                </div>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  2. As a secretary of your school's Health Club, issue a notice
                  requesting the students to join in the garbage cleaning
                  operation in the school premises.
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  3. Suppose the student of your school is suffering from a
                  heart disease. He needs financial help for proper treatment as
                  a secretary of your school, write a notice to make a
                  contribution for the ailing student.&nbsp;
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  4. your school has decided to observe the Rabindra jayanti in
                  your school as the secretary of the students culture committee
                  writes a notice to the student to your school.
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  5. Write a notice your school is going to host the
                  Inter-school District Sports Competition. Suppose you are the
                  secretary of the Sports Club of your school.&nbsp;
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  6. Write a notice on a relief fund of your school. Suppose you
                  are the secretary of your school committee. Your school is
                  going to create a fund for the flood victims. Write a notice
                  within 100 words.&nbsp;
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  7. Write a notice for the school notice board as the secretary
                  of the cultural section of the school informing the
                  observation of the Independence Day.
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  8. Suppose you are to open a library. Write a notice to the
                  members of your programme by donating books for the library.
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  9. Suppose you are the secretary of the student welfare
                  committee of your school. The local police station organised a
                  quiz cum extempore in this competition. write a notice
                  informing and inviting students of class x and xii to
                  participate in the program.
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  10. Suppose you are the secretary of the Eco club of your
                  school and now write a notice for the students on "safe
                  drinking water" to make them conscious about the problems of
                  drinking water.&nbsp;
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  11. Organising awareness programme for : dengue/reckless
                  driving/ Bad effects of drinking water/COVID&nbsp;
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  12. World Environment Day celebration.
                </font>
              </span>
            </div>
            <div>
              <font color="#0d89c5">
                <span>13. </span>
                <span>School magazine.</span>
              </font>
            </div>
            <div>
              <span>
                <font color="#0d89c5">14. Tree Plantation Programme&nbsp;</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">15. Debate/ Quiz Competition</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">
                  16. Farewell Meeting of a teacher&nbsp;
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">17. Educational Tour</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">18. Keep your School Clean</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">19. Swachh Bharat Mission</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">20. ban of mobile phone</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d89c5">21. Music Competition</font>
              </span>
            </div>
            <br />
            <br />
            <div>
              <span>
                <font color="#0d7bc5">
                  ❑ প্রতিষ্ঠান কর্তৃক NOTICE প্রদান করা হবে তার নাম পৃষ্ঠার উপরে
                  হবে।
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d7bc5">
                  <br />
                </font>
              </span>
              <span>
                <font color="#0d7bc5">
                  ❑ NOTICE শব্দটি স্পষ্টভাবে খাতার পাতায় মাঝখানে লিখতে হবে।
                  ক্যাপিটাল লেটারে লিখতে হবে।
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d7bc5">
                  <br />
                </font>
              </span>
              <span>
                <font color="#0d7bc5">
                  ❑ পৃষ্ঠার উপরে ডান কোণে NOTICE প্রদানের তারিখ লিখতে হবে।
                  অর্থাৎ যেদিন NOTICE লেখা হবে সেদিনের তারিখ।
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d7bc5">
                  <br />
                </font>
              </span>
              <span>
                <font color="#0d7bc5">
                  ❑ যে বিষয়ে NOTICE লিখতে বলা হচ্ছে তা Subject লিখে প্রশ্ন
                  অনুযায়ী মূল বিষয়টি লিখতে হবে।
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d7bc5">
                  <br />
                </font>
              </span>
              <span>
                <font color="#0d7bc5">
                  ❑ মূল NOTICE টি সুন্দরভাবে passive voice-এ লিখতে হবে।
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d7bc5">
                  <br />
                </font>
              </span>
              <span>
                <font color="#0d7bc5">
                  ❑ ডান দিকে NOTICE এর নীচে NOTICE প্রদানকারির স্বাক্ষর হবে।
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d7bc5">
                  <br />
                </font>
              </span>
              <span>
                <font color="#0d7bc5">
                  ❑ NOTICE-এর নীচে বাম দিকে Issuing authority / Head of the
                  Institution এর Countersign করতে হবে যদি বিদ্যালয় /
                  প্রতিষ্ঠানের বিজ্ঞপ্তি হয় তাহলে।
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d7bc5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d7bc5">
                  ❑ সব শেষে পূর্ণ NOTICE-টি একটি বক্স দ্বারা ঘেরা হলে বেশ
                  আকর্ষণীয় হয়।
                </font>
              </span>
            </div>
            <br />
            <br />
            <div>
              <span>
                <font size="4" color="#047d00">
                  Function related notice writing shortcut&nbsp;
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d7bc5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font size="4" color="#f239c8">
                  Name of the school or organization
                </font>
              </span>
            </div>
            <div>
              <span>
                <font size="4" color="#f239c8">
                  <b>NOTICE</b>
                </font>
              </span>
            </div>
            <div>
              <font color="#f239c8">
                <span>3&nbsp;&nbsp;</span>
                <span>February, 2024</span>
              </font>
            </div>
            <div>
              <font color="#f239c8">
                Subject : যে বিষয়ে NOTICE লিখতে বলা হয় তা লিখবে।&nbsp;
              </font>
            </div>
            <div>
              <font color="#0d7bc5">
                <br />
                <span>
                  It is hereby notified to all the students / This is to inform
                  all the students of (বিদ্যালয়ের / প্রতিষ্ঠানের নাম) that our
                  institution/school has decided to organize (যেটা subject আছে
                  তা লিখতে হবে) on (তারিখ) in (স্থানের নাম)। The program will be
                  start about (কোন সময় শুরু হবে) a.m/p.m and continue till 5 pm.
                  The various programme has been arranged like (খেলা সম্বন্ধে
                  হলে খেলার নাম এবং অনুষ্ঠান সম্বন্ধে হলে অনুষ্ঠানের নাম লিখতে
                  হবে।) and so on.{" "}
                </span>
                <span>
                  The preparations for this function will start soon.{" "}
                </span>
                <span>
                  The interested students who are willing to participate in it,
                  they can summit their names to their respective class teacher
                  within five days. The Chief guest will be present to enrich us
                  with his/her precious speech about the progrmme. All concerned
                  are requested to co-operate to make our noble effort a grand
                  success. So that we may assume the programme as an
                  achievement.&nbsp;
                </span>
                <br />
                <br />
                <span>
                  For further information undersigned may be contacted.
                </span>
              </font>
            </div>
            <div>
              <span>
                <font color="#0d7bc5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <font color="#f239c8">
                Countersigned&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Headmaster&nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; sd/—&nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Rohan Das
              </font>
            </div>
            <div>
              <span>
                <font color="#f239c8">Secretary</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">ABC High School</font>
              </span>
            </div>
            <br />
            <br />
            <div>
              <span>
                <font size="4" color="#0d75c5">
                  Educational tour related notice writing&nbsp;
                </font>
              </span>
            </div>
            <div>
              <span>
                <font size="4" color="#0d75c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font size="4" color="#f239c8">
                  ABC HIGH SCHOOL&nbsp;
                </font>
              </span>
            </div>
            <div>
              <span>
                <font size="4" color="#f239c8">
                  NOTICE&nbsp;
                </font>
              </span>
            </div>
            <div>
              <font color="#f239c8">
                <span>
                  Ref No. 25B/23 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;{" "}
                </span>
                <span>Date :- March 3, 2024</span>
              </font>
            </div>
            <div>
              <span>
                <font color="#f239c8">Educational Tour/Excursion</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d75c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <font color="#0d75c5">
                <span>
                  It is hereby notified to all the students / This is to inform
                  all the students of (বিদ্যালয়ের / প্রতিষ্ঠানের নাম) that our
                  institution/school has decided to organize
                </span>
                <span>
                  {" "}
                  an educational tour to _____ (যেখানে যাবে সেই জায়গার নাম) on
                  ---------- (date). It will be a ______ (কত দিনের tour) day
                  tour. The tour will start by bus at 8AM on ------- (date) from
                  the school gate and is expected to return to the school
                  premises at about&nbsp; 7 P.M on ….. (date). Interested
                  students are requested to enlist their names giving Rs-250 to
                  the secretary. They should enlist their names on or before
                  &nbsp; (date--------).&nbsp; They are requested to take their
                  clothes, tooth-brush, toothpaste, soap, water bottles, torch
                  light and any other article for daily use. The interested
                  students must submit the consent letters from their
                  guardians.&nbsp;
                </span>
                <span>
                  For Further details, please contact the undersigned.
                </span>
              </font>
            </div>
            <div>
              <span>
                <font color="#0d75c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <font color="#f239c8">
                Countersigned&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Headmaster &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; Rohan Das
              </font>
            </div>
            <div>
              <span>
                <font color="#f239c8">Secretary</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">ABC High School</font>
              </span>
            </div>
            <br />
            <br />
            <div>
              <span>
                <font color="#0d75c5">
                  <b>Camp related notice writing</b>
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d75c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">ABC HIGH SCHOOL&nbsp;</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">NOTICE&nbsp;</font>
              </span>
            </div>
            <div>
              <font color="#f239c8">
                <span>
                  Ref No. 25B/23 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;
                </span>
                <span>Date :- March 3, 2024</span>
              </font>
            </div>
            <div>
              <font color="#f239c8">Subject&nbsp;</font>
            </div>
            <div>
              <span>
                <font color="#0d75c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <font color="#0d75c5">
                <span>
                  It is hereby notified to&nbsp; / This is to inform - all the
                  students of (বিদ্যালয়ের / প্রতিষ্ঠানের নাম) that our
                  institution/school is going to organize (যেটা subject আছে তা
                  লিখতে হবে) on (তারিখ) in (স্থানের নাম)। The camp will be start
                  about (কোন সময় শুরু হবে) a.m/p.m and continue till 5 pm. A
                  team of doctors and nurse from the district hospitals will
                  conduct a test of (এ যেটা বলা হবে তা লিখতে হবে). Free
                  medicines and health tips will also be provided to the
                  visitors. Attendance is compulsory and no fees need to paid.
                  The students are requested to register their names with their
                  respective class teacher within five days. The Chief guest
                  will be present to enrich us with his/her precious speech
                  about the progrmme. All concerned are requested to co-operate
                  to make our noble effort a grand success. So that we may
                  assume the programme as an achievement.
                </span>
                <span>&nbsp;</span>
                <span>
                  For Further details, please contact the undersigned.
                </span>
              </font>
            </div>
            <div>
              <span>
                <font color="#0d75c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">
                  Countersigned&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;Sd/
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">
                  Headmaster &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Rohan
                  Das
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">Secretary</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">ABC High School</font>
              </span>
            </div>
            <br />
            <br />
            <div>
              <font color="#0d75c5">
                <span>Competition related notice writing</span>
                <span>&nbsp;</span>
              </font>
            </div>
            <div>
              <div>
                <font color="#0d75c5">
                  <br />
                </font>
              </div>
              <div>
                <font color="#f239c8">ABC HIGH SCHOOL&nbsp;</font>
              </div>
              <div>
                <font color="#f239c8">
                  <b>NOTICE</b>&nbsp;
                </font>
              </div>
              <div>
                <font color="#f239c8">
                  Ref No. 25B/23 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;Date :- March 3, 2024
                </font>
              </div>
              <div>
                <font color="#f239c8">Subject :</font>
              </div>
              <div>
                <font color="#0d75c5">
                  <br />
                </font>
              </div>
            </div>
            <div>
              <div>
                <font color="#0d75c5">
                  <span>
                    It is hereby notified to all the students / This is to
                    inform all the students of (বিদ্যালয়ের / প্রতিষ্ঠানের নাম)
                    that our institution/school has decided to organize (যেটা
                    subject আছে তা লিখতে হবে) on (তারিখ) in (স্থানের নাম)। The
                    program will be start about (কোন সময় শুরু হবে তা লেখো)
                    a.m/p.m and continue till (কোন সময় শেষ হবে তা লেখো) pm.{" "}
                  </span>
                  <span>
                    The preparations for this competition will start soon.{" "}
                  </span>
                  <span>
                    The interested students who are willing to participate in
                    the competition are requested to summit their names to their
                    respective class teacher within five days. The competition
                    will be only on (কোন বিষয়ের ওপর ভিত্তি করে নেওয়া হবে তা
                    লেখো). There will be junior groups for class v to VIII and
                    the senior groups for class&nbsp; IX to XII of four teams
                    each group.&nbsp; Only the best team of each group will be
                    given a prize. A few judges will be invited to the
                    competition. The Chief guest will be present to enrich us
                    with his/her precious speech about the competition.&nbsp;
                  </span>
                </font>
              </div>
              <div>
                <span>
                  <font color="#0d75c5">
                    <br />
                  </font>
                </span>
              </div>
              <span>
                <div>
                  <span>
                    For further information undersigned may be contacted.
                  </span>
                </div>
              </span>
            </div>
            <div>
              <span>
                <font color="#0d75c5">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <font color="#f239c8">
                Countersigned by&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Headmaster &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; Rohan Das
              </font>
            </div>
            <div>
              <span>
                <font color="#f239c8">Secretary</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">ABC High School</font>
              </span>
            </div>
            <br />
            <br />
            <div>
              <span>
                <font color="#f239c8">
                  1. Suppose you are the secretary of ABC CLUB. Your club is
                  going to organise a blood donation camp in your club premises.
                  Write a notice inviting the people of your locality for donate
                  blood in a blood donation camp.
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0776cf">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font size="4" color="#f239c8">
                  ABC CLUB
                </font>
              </span>
            </div>
            <div>
              <span>
                <font size="4" color="#f239c8">
                  NOTICE
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">Date :- March 3, 2024</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">Blood Donation Camp&nbsp;&nbsp;</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0776cf">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0776cf">
                  All the&nbsp; members of the club and the people of the
                  locality are hereby informed that our club is going to
                  organise a blood donation camp. It will be held in the club
                  premises on March 3, 2024. It will be started at 9.00 a.m. and
                  continued till 5 p.m. BDO will be present at the camp to
                  inspire people to donate blood. We're asking all club members
                  and people from our community aged 20 to 50 to come to the
                  camp and give blood. Donors will get a delicious and healthy
                  lunch and&nbsp; a special certificate to say thank you. We
                  really need your wholehearted help and support. We need
                  everyone's help to make this good thing a big success. If you
                  want more information about this program then you can get it
                  from us.
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0776cf">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <font color="#0776cf">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
              </font>
              <font color="#f239c8">
                Sd/&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Rohan&nbsp; Das
              </font>
            </div>
            <div>
              <span>
                <font color="#f239c8">Secretary</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">ABC High School</font>
              </span>
            </div>
            <br />
            <br />
            <div>
              <span>
                <font color="#f239c8">
                  2. As a secretary of your school's Health Club, issue a notice
                  requesting the students to join in the garbage cleaning
                  operation in the school premises.
                </font>
              </span>
            </div>
            <div>
              <br />
              <font size="4" color="#f239c8">
                NARAYANPUR ABC CLUB&nbsp;
              </font>
            </div>
            <div>
              <span>
                <font size="4" color="#f239c8">
                  NOTICE&nbsp;
                </font>
              </span>
            </div>
            <div>
              <font color="#f239c8">
                <span>
                  Ref No. 25B/23&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;&nbsp;
                </span>
                <span>Date :- March 3, 2024</span>
              </font>
            </div>
            <div>
              <span>
                <font color="#f239c8">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">GARBAGE CLEARING OPERATION</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0776cf">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <font color="#0776cf">
                <span>
                  All the&nbsp; members of the club are hereby informed that our
                  club is going to organize a Garbage Cleaning Operation in this
                  locality. It will be held on March 3, 2024. We all know it's
                  important to keep our neighborhood clean. So, let's meet at
                  the club at 8:00 a.m. and work together to clean the drains
                  and streets. They should bring with them brooms, buckets,
                  spades, phenyl and bleaching powder. The cleaning operation is
                  likely to continue for about three hours. It is expected that
                  every member of the club will heartily respond to the
                  request.&nbsp;
                </span>
                <span>
                  We really need everyone to work together. If you have any
                  questions, feel free to reach out to me. We really need your
                  help and support.
                </span>
              </font>
            </div>
            <div>
              <font color="#0776cf">
                <span>
                  <br />
                </span>
              </font>
            </div>
            <div>
              <font color="#f239c8">
                Countersigned by&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; Headmaster &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; Rohan Das
              </font>
            </div>
            <div>
              <span>
                <font color="#f239c8">Secretary</font>
              </span>
            </div>
            <div>
              <font color="#f239c8">ABC High School</font>
            </div>
            <br />
            <br />
            <div>
              <span>
                <font color="#f239c8">
                  3. Suppose the student of your school is suffering from a
                  heart disease. He needs financial help for proper treatment as
                  a secretary of your school, write a notice to make a
                  contribution for the ailing student.
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">ABC HIGH SCHOOL&nbsp;</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">NOTICE&nbsp;</font>
              </span>
            </div>
            <div>
              <font color="#f239c8">
                <span>
                  Ref No. 25B/23&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp;&nbsp;
                </span>
                <span>Date :- March 3, 2024</span>
              </font>
            </div>
            <div>
              <span>
                <font color="#f239c8">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">
                  Raising Fund for the Treatment of a student/our schoolmate
                </font>
              </span>
            </div>
            <div>
              <span>
                <font color="#0776cf">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <font color="#0776cf">
                <span>
                  All the students of our class are hereby informed that we are
                  going to raise funds for the treatment of Rahul Ray, a student
                  of Class VIII, Sec. A of our school. Rahul&nbsp; is suffering
                  from cancer and his treatment requires money to the tune of a
                  million rupees. His family cannot bear such a huge burden. So,
                  it is our duty to contribute generously to the fund. Your
                  contribution in cash only should be handed over to the
                  undersigned during the recess hour on or before 5 June,
                  2024.&nbsp;&nbsp;
                </span>
                <span>
                  &nbsp;For any further information, please contact the
                  undersigned. Let our mites be consolidated so that we can save
                  our schoolmate from such a fatal disease.
                </span>
              </font>
            </div>
            <div>
              <span>
                <font color="#0776cf">
                  <br />
                </font>
              </span>
            </div>
            <div>
              <font color="#f239c8">
                Countersigned by&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; Headmaster &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; Rohan Das
              </font>
            </div>
            <div>
              <span>
                <font color="#f239c8">Secretary</font>
              </span>
            </div>
            <div>
              <span>
                <font color="#f239c8">ABC High School</font>
              </span>
            </div>
            <br />
            <br />
          </div>
          <div></div>
        </div>
      )}
      <br />
      {/* <a
        className="btn btn-primary py-3 px-5 mt-2"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.isbawb"
      >
        Check Our Mobile App
      </a> */}
    </div>
  );
}

NOTICEWRITING.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(NOTICEWRITING);
